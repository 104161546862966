import React, { useEffect, useMemo, useState } from 'react';
import { BootResponse, OfferData } from 'constants/apiResponses.types';
import useApi from 'hooks/useApi';
import {
  EBundlesInternalViewModel,
  EQueryParams,
  ESpecialOfferInternalViewModel,
} from 'constants/enums';
import { isMobileOnly } from 'react-device-detect';
import Carousel from 'components/Carousel/Carousel';
import CarouselItem from 'components/Carousel/CarouselItem';
import { SpecialOffer } from 'components/SpecialOffer/SpecialOffer';
import '../style.scss';
import useUrlQuery from 'hooks/useUrlQuery';

const CAROUSEL_LIMIT = 8;

interface ISpecialOffersWrapperProps {
  selectOffer: (offerData: OfferData) => void;
}

export const SpecialOffersWrapper: React.FC<ISpecialOffersWrapperProps> = ({ selectOffer }) => {
  const campaignOfferId = useUrlQuery(EQueryParams.OFFER_ID);
  const [specialOffersList, setSpecialOffersList] = useState<any>([]);
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const { bundlesInternalViewModel, specialOffersInternalViewModel } =
    publisherMetaData.storeTheme.general;

  useEffect(() => {
    if (!API.getOffersV2.data?.offers?.promotions || campaignOfferId) return;

    setSpecialOffersList(API.getOffersV2.data.offers.promotions);
  }, [API.getOffersV2.data?.offers?.promotions]);

  const isCarouselView = useMemo(
    () =>
      specialOffersInternalViewModel !== ESpecialOfferInternalViewModel.PLAY_SIMPLE &&
      (!isMobileOnly ||
        specialOffersInternalViewModel !== ESpecialOfferInternalViewModel.POKER_FACE),
    [specialOffersInternalViewModel]
  );

  const isDividerDisplay = useMemo(
    () =>
      [
        EBundlesInternalViewModel.LOCO_BINGO,
        EBundlesInternalViewModel.BAGELCODE,
        EBundlesInternalViewModel.PIPA,
        EBundlesInternalViewModel.PLAY_SIMPLE,
      ].includes(bundlesInternalViewModel),
    [bundlesInternalViewModel]
  );

  return (
    <>
      {specialOffersList?.length > 0 &&
        (isCarouselView ? (
          <Carousel showDivider={isDividerDisplay} bundleViewModel={specialOffersInternalViewModel}>
            {specialOffersList.slice(0, CAROUSEL_LIMIT).map((offer: OfferData, i: number) => (
              <CarouselItem key={i} data={offer}>
                <SpecialOffer data={offer} selectOffer={() => selectOffer(offer)} />
              </CarouselItem>
            ))}
          </Carousel>
        ) : (
          <div className="offers-wrapper--column-view">
            {specialOffersList.map((offer: OfferData, i: number) => (
              <SpecialOffer data={offer} selectOffer={() => selectOffer(offer)} key={i} />
            ))}
          </div>
        ))}
    </>
  );
};
