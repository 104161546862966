import { HeaderWrapper } from 'components/HeaderWrapper/HeaderWrapper';
import useApi from 'hooks/useApi';
import { BootResponse, OfferData } from 'constants/apiResponses.types';
import LogoutModal from 'components/LogoutModal/LogoutModal';
import { MessageBox, ProductData, useIsDesktop, useIsLandscape } from '@appcharge/shared-ui';
import { useNavigate } from 'react-router-dom';
import UAParser from 'ua-parser-js';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  EBundlesInternalViewModel,
  ECheckoutPageEvent,
  ECollectType,
  EEventsType,
  ELocalStorageKeys,
  ELoginEvents,
  EOfferType,
  EPublisherWebhookEventType,
  EPublisherWebhookOfferEventType,
  EQueryParams,
  EStorePhase,
  StoreBodyBlock,
  EOrderStatus,
} from 'constants/enums';
import { extractOfferMainDetails, getAppVersion, getBadgeName, getPlatformData } from 'utils';
import { localStorageUtil } from 'state/localStorage.state.service';
import useUrlQuery from 'hooks/useUrlQuery';
import './style.scss';
import useCustomEvents from 'hooks/useCustomEvents';
import Footer from 'components/footer/footer';
import { isAndroid, isIOS } from 'react-device-detect';
import { BundlesWrapper } from './components/BundlesWrapper';
import Checkout from 'components/Checkout/Checkout';
import useSaveToHomePage from 'hooks/useSaveToHomePage';
import AddToHomePage from 'components/AddToHomePage/AddToHomePage';
import { PlayerLevelWrapper } from './components/PlayerLevelWrapper';
import { useLocalizationState } from 'state/hooks/localization.state.hook';
import { BannerWrapper } from './components/BannerWrapper';
import { MissingOffersBlock } from './components/MissingOffersBlock';
import { SpecialOffersWrapper } from './components/SpecialOffersWrapper';
import { useAppState } from 'state/hooks/app.state.hook';
import { CreateOrderPayload } from 'constants/apiRequest.types';
import { Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { usePopupState } from 'state/hooks/popup.state.hook';
import { PopupsWrapper } from './components/PopupsWrapper';
import useLoginEvents from 'hooks/useLoginEvents';
import { RollingOffersWrapper } from './components/RollingOffersWrapper';
import { DailyBonusWrapper } from './components/DailyBonusWrapper';
import { useLoadImages } from './hooks/useLoadImages.hook';

const OFFERS_INTERVAL_TIME = 30000;

interface IShopPageProps {
  logout: (utmSource?: string) => void;
}

interface IOfferBalance {
  images: {
    product: string;
    productPrefix: string;
  };
  product: ProductData;
  quantity: string;
}

const DEFAULT_BODY_BLOCKS_ORDER: StoreBodyBlock[] = [
  StoreBodyBlock.dailyBonus,
  StoreBodyBlock.playerLevel,
  StoreBodyBlock.banners,
  StoreBodyBlock.specialOffers,
  StoreBodyBlock.bundles,
];

const mapBlocksToComponents: Record<StoreBodyBlock, React.FC<any>> = {
  [StoreBodyBlock.dailyBonus]: DailyBonusWrapper,
  [StoreBodyBlock.playerLevel]: PlayerLevelWrapper,
  [StoreBodyBlock.banners]: BannerWrapper,
  [StoreBodyBlock.specialOffers]: SpecialOffersWrapper,
  [StoreBodyBlock.rollingOffers]: RollingOffersWrapper,
  [StoreBodyBlock.bundles]: BundlesWrapper,
};

export const ShopPage: React.FC<IShopPageProps> = ({ logout }) => {
  //store config states:
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  //order states:
  const [activeOrderId, setActiveOrderId] = useState<string>('');
  const [processing, setProcessing] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [shouldLoaderBeShown, setShouldLoaderBeShown] = useState(false);

  //offer states:
  const [offersLoadedReported, setOffersLoadedReported] = useState<boolean>(false);
  const [indexToCollect, setIndexToCollect] = useState(0);

  const navigate = useNavigate();
  const API = useApi({ orderId: activeOrderId });
  const { sendPublisherWebhookLoginEvent } = useLoginEvents();
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const { bundlesInternalViewModel } = publisherMetaData.storeTheme.general;
  const utmSource = useUrlQuery(EQueryParams.UTM_SOURCE);
  const from = useUrlQuery(EQueryParams.FROM);
  const d10xLinkId = useUrlQuery(EQueryParams.DYNAMO_LINK_ID);
  const d10xOrg = useUrlQuery(EQueryParams.DYNAMO_ORG);
  const campaignToken = useUrlQuery(EQueryParams.TOKEN);
  const [campaignOfferId, setCampaignOfferId] = useState<string>();
  const [loadingFromCampaign, setLoadingFromCampaign] = useState(from === 'campaign');
  const isLoginRedirect = useUrlQuery(EQueryParams.LOGIN_REDIRECT) === 'true';
  const customEvents = useCustomEvents();
  const [bodyBlocksOrder, setBodyBlocksOrder] = useState<StoreBodyBlock[]>([]);

  const fetchInterval = useRef(0);

  const isDesktop = useIsDesktop();
  const isLandscape = useIsLandscape();
  const { isDisplayAddToHomePageButton } = useSaveToHomePage(publisherMetaData);
  const { currentLanguage } = useLocalizationState();
  const { allImagesLoaded } = useLoadImages();
  const { setDisplayLoader, selectedOffer, setSelectedOffer } = useAppState();
  const {
    popupOffers,
    badgeTimerLabel,
    dailyBonusBadges,
    setCurrentPopupDisplayed,
    setDisplayPostPurchase,
  } = usePopupState();

  useEffect(() => {
    if (!API.getOffersV2.data?.offers) return;

    if (!offersLoadedReported) {
      const offersExtractedData = [
        ...extractOfferMainDetails(API.getOffersV2.data.offers.promotions),
        ...extractOfferMainDetails(API.getOffersV2.data.offers.bundles),
        ...extractOfferMainDetails(API.getOffersV2.data.offers.popups),
      ];
      customEvents.sendCustomEvent(
        EEventsType.OFFERS_LOADED,
        {
          specialOffers: API.getOffersV2.data.offers.promotions?.length,
          bundles: API.getOffersV2.data.offers.bundles?.length,
          popups: API.getOffersV2.data.offers.popups?.length,
          offersData: offersExtractedData,
        },
        EStorePhase.POST_LOGIN
      );
      API.sendPublisherWebhookEvent.mutate({
        eventType: EPublisherWebhookEventType.OFFER,
        data: {
          eventName: EPublisherWebhookOfferEventType.OFFERS_LOADED,
          appVersion: getAppVersion(),
          offers: offersExtractedData,
        },
      });
      setOffersLoadedReported(true);
    }
  }, [API.getOffersV2.data]);

  useEffect(() => {
    if (API.getOffersV2.isError) {
      customEvents.resetSession();
      logout();
    }
  }, [API.getOffersV2.isError]);

  useEffect(() => {
    document.querySelector('body')?.classList.remove('screen-lock');
  }, []);

  useEffect(() => {
    const setBackground = () => {
      const backgroundImage =
        isDesktop || isLandscape
          ? publisherMetaData.storeTheme.general.backgroundImageDesktop
          : publisherMetaData.storeTheme.general.backgroundImageMobile;
      document.body.style.backgroundImage = `url(${backgroundImage})`;
      document.body.style.backgroundSize = 'cover';
      document.body.style.backgroundAttachment = 'fixed';
      document.body.style.backgroundPosition = 'top center';
    };

    const checkIframeVisibility = () => {
      const iframe = document.getElementById('hs-web-sdk-iframe');
      if (iframe && window.getComputedStyle(iframe).visibility === 'visible') {
        setIsScriptLoaded(true);
        return true;
      }
      return false;
    };

    const loadSupportScript = () => {
      const scriptUrl = publisherMetaData.supportConfiguration?.postLoginSnippet;
      if (!scriptUrl) {
        setIsScriptLoaded(true);
        return;
      }

      const script = document.createElement('script');
      script.src = scriptUrl;
      script.onload = () => {
        if (!checkIframeVisibility()) {
          const intervalId = setInterval(() => {
            if (checkIframeVisibility()) {
              clearInterval(intervalId);
            }
          }, 100);
        }
      };
      document.body.appendChild(script);

      return script;
    };

    setBackground();
    const script = loadSupportScript();
    const handleFocus = () => keepAlive();
    const handleBlur = () => clearTimeout(fetchInterval.current);

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
      if (script) document.body.removeChild(script);
    };
  }, [isDesktop, isLandscape, publisherMetaData]);

  useEffect(() => {
    if (!isLoggedIn) return;
    const init = async () => {
      const parser = new UAParser();
      const info = parser.getResult();
      customEvents.sendCustomEvent(
        EEventsType.SHOP_LAND,
        {
          player_activity: isLoginRedirect ? 'post login' : 'normal',
          platform: getPlatformData(),
          source: sessionStorage.getItem('source'),
          add_to_home_screen_appear: isDisplayAddToHomePageButton,
          device_manufacture: info.device.vendor,
          device_model: info.device.model,
          os: info.os.name,
          os_version: info.os.version,
        },
        EStorePhase.POST_LOGIN
      );
      sendPublisherWebhookLoginEvent({
        eventName: ELoginEvents.SHOP_LAND,
        options: {
          playerId: localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA).playerId,
        },
      });
      API.sendShopLand.mutate();
      await API.getPostLoginData.refetch();
    };
    init();
    return () => {
      clearTimeout(fetchInterval.current);
    };
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const keepAlive = async () => {
    if (!localStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN)) {
      return logout(utmSource || undefined);
    }

    API.keepAlive.mutate(undefined, {
      onError: () => {
        customEvents.sendCustomEvent(
          EEventsType.LOGOUT,
          {
            reason: 'session ended',
          },
          EStorePhase.PRE_LOGIN
        );
        customEvents.resetSession();
        logout();
      },
      onSuccess: () => {
        customEvents.setDistinctId();
        fetchInterval.current = Number(setTimeout(fetchIntervalHandler, OFFERS_INTERVAL_TIME));
        if (!isLoggedIn) setIsLoggedIn(true);
      },
    });
  };

  useEffect(() => {
    if (API.keepAlive.isIdle) {
      keepAlive();
    }
  }, [API.keepAlive.isIdle]);

  const openLogoutModal = () => {
    customEvents.sendCustomEvent(EEventsType.PROFILE_MENU_CLICKED, {}, EStorePhase.POST_LOGIN);
    setShowLogoutModal(true);
  };

  const fetchIntervalHandler = () => {
    if (!document.hasFocus()) return;
    fetchInterval.current = Number(setTimeout(fetchIntervalHandler, OFFERS_INTERVAL_TIME));
  };

  useEffect(() => {
    if (!publisherMetaData.featureFlags?.store_refresh_offers_stale_mode) return;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        API.getOffersV2.refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [publisherMetaData.featureFlags]);

  useEffect(() => {
    const loadingState = processing || !allImagesLoaded || loadingFromCampaign;
    setShouldLoaderBeShown(loadingState);
  }, [processing, allImagesLoaded, loadingFromCampaign]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayLoader(shouldLoaderBeShown);
    }, 300);

    return () => clearTimeout(timer);
  }, [shouldLoaderBeShown]);

  const onCollect = async (offerData: OfferData): Promise<void> => {
    setDisplayLoader(true);
    const freeOrderData: Partial<CreateOrderPayload> = {
      clientGaId: localStorage.getItem('ga_client_id') || '2134654321',
      paymentProvider: 'free',
      paymentMethod: 'free',
      utmSource: null,
      offerId: offerData.offerId,
    };
    try {
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_CLICKED,
        {
          offer_id: offerData.offerId,
          offer_name: offerData.offerName,
          platform: getPlatformData(),
          type: ECollectType.OFFER,
        },
        EStorePhase.POST_LOGIN
      );

      const response = await API.createOrderV2.mutateAsync(freeOrderData);
      const orderId = response?.data?.orderId;

      if (orderId) {
        setActiveOrderId(orderId);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('order creating failed', axiosError);
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_RESOLVED,
        {
          offer_Id: offerData.offerId,
          offer_name: offerData.offerName,
          status: EOrderStatus.REQUEST_FAILED,
          platform: getPlatformData(),
          type: offerData.offerType,
          reason: axiosError.response?.statusText,
          statusCode: axiosError.response?.status,
        },
        EStorePhase.POST_LOGIN
      );
      navigate('../failed?msg=creating order error');
    }
  };

  const selectOffer = (offerData: OfferData) => {
    setProcessing(true);

    const productIndexToCollect =
      offerData.offerType === EOfferType.POP_UP || offerData.offerType === EOfferType.ROLLING_OFFER
        ? indexToCollect
        : 0;
    setSelectedOffer(offerData);

    const priceData = offerData?.productsSequence[productIndexToCollect]?.price;
    if (priceData?.price === 0) {
      localStorageUtil.set(ELocalStorageKeys.IS_FREE_ORDER_SELECTED, true);
      onCollect(offerData);
    } else {
      localStorageUtil.remove(ELocalStorageKeys.IS_FREE_ORDER_SELECTED);
      setShowPaymentModal(true);
      const products = offerData?.productsSequence[productIndexToCollect]?.products.map(
        (product) => {
          const { name, quantity } = product;
          return {
            name,
            quantity,
          };
        }
      );
      customEvents.sendCustomEvent(
        EEventsType.OFFER_CLICKED,
        {
          offer_id: offerData.offerId,
          offer_name: offerData.offerName,
          badge_left: getBadgeName('left', offerData.dynamicOfferUi?.badges),
          badge_right: getBadgeName('right', offerData.dynamicOfferUi?.badges),
          badge_center: getBadgeName('center', offerData.dynamicOfferUi?.badges),
          sale_percentage: offerData.dynamicOfferUi?.salePercentage,
          type: offerData.offerType,
          sub_type: offerData?.subType,
          products,
          bundle_price_usd: priceData.price,
          player_local_currency: priceData.currencyCode,
          bundle_price_total: priceData.price,
          offer_id_external: offerData.publisherOfferId,
          offer_internal_id: offerData.offerId,
        },
        EStorePhase.POST_LOGIN
      );
    }
  };
  const closePopup = () => {
    setDisplayLoader(false);
    setShowLogoutModal(false);
    setShowPaymentModal(false);
    setActiveOrderId('');
    setCurrentPopupDisplayed(null);
    setSelectedOffer(null);
    setLoadingFromCampaign(false);
    setCampaignOfferId(undefined);
  };

  const getBalancesData = () => {
    const data: IOfferBalance[] = API.getOffersV2?.data?.balances;
    if (!data) return;
    return data.map((item) => ({
      ...item,
      image: item.images.productPrefix || item.images.product,
      amount: item.quantity,
    }));
  };

  //open next product badge
  const onClickBadge = (offerId?: string) => {
    const clickedPopupBadge = popupOffers?.find((offer) => offer.offerId === offerId);
    if (clickedPopupBadge) {
      setCurrentPopupDisplayed(null);
      setTimeout(() => {
        const updatedPopup = {
          ...clickedPopupBadge,
          isBadgeNextProduct: true,
        };
        if (updatedPopup?.indexToCollect) setIndexToCollect(updatedPopup.indexToCollect - 1);
        setCurrentPopupDisplayed(updatedPopup);
      }, 0);
    }
  };

  useEffect(() => {
    const eventHandler = (massageEvent: any) => {
      if (massageEvent.origin !== window.location.origin) return;
      const { params, event } = massageEvent.data;

      if (event === ECheckoutPageEvent.BACK_TO_STORE) {
        const isRollingOffer =
          typeof selectedOffer !== 'string' &&
          (selectedOffer as OfferData)?.offerType === EOfferType.ROLLING_OFFER;

        if (!isRollingOffer) {
          setDisplayPostPurchase(true);
          API.getOffersV2.refetch();
        }
        return;
      }
      if (event === ECheckoutPageEvent.SUPPORT) {
        navigate(params.supportUrl);
        return;
      }
    };

    window.addEventListener('message', eventHandler);

    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  useEffect(() => {
    API.getOffersV2.refetch();
  }, [currentLanguage]);

  useEffect(() => {
    if (from === 'campaign' && campaignToken) {
      API.getStoreCampaign.mutate(
        {
          tokenId: campaignToken,
          linkId: d10xLinkId || undefined,
          org: d10xOrg || undefined,
        },
        {
          onSuccess: (data: string) => {
            setCampaignOfferId(data);
          },
        }
      );
    }
  }, [from, campaignToken, d10xLinkId, d10xOrg]);

  const mapBlocksToProps: Record<StoreBodyBlock, Object> = useMemo(
    () => ({
      [StoreBodyBlock.dailyBonus]: {
        dailyBonusBadges,
        timer: badgeTimerLabel,
        font: publisherMetaData.storeTheme.general.font,
        bundlesInternalViewModel: publisherMetaData.storeTheme.general.bundlesInternalViewModel,
        onClickBadge,
      },
      [StoreBodyBlock.playerLevel]: {},
      [StoreBodyBlock.banners]: {},
      [StoreBodyBlock.specialOffers]: { selectOffer },
      [StoreBodyBlock.rollingOffers]: { selectOffer, setProcessing },
      [StoreBodyBlock.bundles]: { selectOffer },
    }),
    [
      dailyBonusBadges,
      badgeTimerLabel,
      publisherMetaData.storeTheme.general.font,
      selectOffer,
      setProcessing,
    ]
  );

  useEffect(() => {
    const publisherBodyBlocks = publisherMetaData.storeTheme?.storeTemplate?.bodyBlocksOrder;

    // If the publisher body blocks is not defined / empty array, use default
    const arrBlocksOrder: StoreBodyBlock[] =
      publisherBodyBlocks && publisherBodyBlocks.length
        ? publisherBodyBlocks
        : DEFAULT_BODY_BLOCKS_ORDER;

    setBodyBlocksOrder(arrBlocksOrder);
  }, [publisherMetaData.storeTheme?.storeTemplate?.bodyBlocksOrder]);

  useEffect(() => {
    if (publisherMetaData.featureFlags?.store_hide_mobile_background_overlay) {
      let viewportMeta = document.querySelector('meta[name="viewport"]');
      if (!viewportMeta) {
        viewportMeta = document.createElement('meta');
        viewportMeta.setAttribute('name', 'viewport');
        document.head.appendChild(viewportMeta);
      }
      viewportMeta.setAttribute(
        'content',
        'initial-scale=1, width=device-width, height=device-height, viewport-fit=cover, user-scalable=no'
      );
    }
  }, [publisherMetaData]);

  return (
    <div className="storeWrapper">
      <AddToHomePage publisherMetaData={publisherMetaData} customEvents={customEvents} />
      {(isAndroid || isIOS) &&
        !publisherMetaData.featureFlags?.store_hide_mobile_background_overlay && (
          <div
            className={'shop__mobile-img'}
            style={{
              backgroundImage: `url(${publisherMetaData.storeTheme.general.backgroundImageMobile})`,
              position: 'fixed',
              zIndex: '-1',
              backgroundSize: 'cover',
              backgroundPosition: 'top center',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          ></div>
        )}

      {publisherMetaData.featureFlags.store_deeplinks_disable_store && !from && (
        <Stack alignItems="center" justifyContent="center" width="100%" height="100vh">
          <MessageBox
            title={'This link is not valid'}
            text={'Sorry, this link is no longer valid.'}
            fontFamily={publisherMetaData.storeTheme.general.font}
            borderColor={'#FFFFFF'}
          />
        </Stack>
      )}

      {!publisherMetaData.featureFlags.store_deeplinks_disable_store && (
        <HeaderWrapper
          showHeader={API.getOffersV2.data !== undefined}
          balanceQuantityFormat={publisherMetaData.storeTheme.general.balanceQuantityFormat}
          playerName={localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)?.playerName}
          openLogoutModal={openLogoutModal}
          logoSize={publisherMetaData.storeTheme.general.logoSize}
          balances={getBalancesData()}
          fontFamily={publisherMetaData.storeTheme.general.font}
          profileFrameImg={API.getOffersV2?.data?.profileFrameImg || ''}
          playerLevelImg={API.getOffersV2?.data?.playerLevelImg || ''}
        />
      )}
      <PopupsWrapper
        setProcessing={setProcessing}
        selectOffer={selectOffer}
        showPaymentModal={showPaymentModal}
      />

      {!publisherMetaData.featureFlags.store_deeplinks_disable_store && (
        <div className="offers-wrapper">
          {bodyBlocksOrder.map((key: StoreBodyBlock) => {
            const Block: React.FC = mapBlocksToComponents[key];

            return <Block key={key} {...mapBlocksToProps[key]} />;
          })}

          <MissingOffersBlock />

          {(bundlesInternalViewModel === EBundlesInternalViewModel.BAGELCODE ||
            bundlesInternalViewModel === EBundlesInternalViewModel.BAGELCODE2) &&
            API.getOffersV2?.data?.playerLevelImg && (
              <div className="player-level-image">
                <img src={publisherMetaData.storeTheme.general.logo} alt="logo" />
                <img alt="Player Level" src={API.getOffersV2?.data?.playerLevelImg} />
              </div>
            )}
        </div>
      )}

      {!publisherMetaData.featureFlags.store_deeplinks_disable_store && (
        <Footer showFooter={API.getOffersV2.data !== undefined} isScriptLoaded={isScriptLoaded} />
      )}
      {showLogoutModal && (
        <LogoutModal
          playerName={localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)?.playerName}
          closePopup={closePopup}
          showLogoutModal={showLogoutModal}
          logout={logout}
        />
      )}
      {((selectedOffer && showPaymentModal) || campaignOfferId || activeOrderId) && (
        <Checkout
          selectedOffer={(campaignOfferId as string) || (selectedOffer as OfferData)}
          close={() => closePopup()}
          setProcessing={setProcessing}
          setSelectedOffer={setSelectedOffer}
          setLoadingFromCampaign={setLoadingFromCampaign}
          collectId={activeOrderId}
          currencyCode={selectedOffer?.productsSequence[indexToCollect]?.price?.currencyCode}
        />
      )}
    </div>
  );
};
