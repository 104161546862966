import React, { useEffect, useRef, useState } from 'react';
import { BootResponse, OfferData } from 'constants/apiResponses.types';
import useApi from 'hooks/useApi';
import {
  EPopupSubType,
  EPublisherWebhookEventType,
  EPublisherWebhookOfferEventType,
} from 'constants/enums';
import '../style.scss';
import { PopUpsHandler } from 'components/PopUpsHandler/PopUpsHandler';
import { TIME_LEFT_INTERVAL } from 'constants/defaults';
import { OrderPopupEvents, getTimeLabel, extractOfferMainDetails, getAppVersion } from 'utils';
import { usePopupState } from 'state/hooks/popup.state.hook';
import { dailyBonusBadgeProps } from 'components/HeaderWrapper/HeaderWrapper.types';

interface IPopupsWrapperProps {
  selectOffer: (offerData: OfferData) => void;
  setProcessing: (processing: boolean) => void;
  showPaymentModal?: boolean;
}

export const PopupsWrapper: React.FC<IPopupsWrapperProps> = ({
  setProcessing,
  selectOffer,
  showPaymentModal,
}) => {
  const dailyBonusRef = useRef<OrderPopupEvents>(null);
  const postPurchaseRef = useRef<OrderPopupEvents>(null);

  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;

  const [popUpStartHourUTC, setPopUpStartHourUTC] = useState<number>(0);
  const [nextAvailableDate, setNextAvailableDate] = useState<any>(false);
  const [popupsPresented, setPopupsPresented] = useState<string[]>([]);
  const [clickedClosePopups, setClickedClosePopups] = useState<string[]>([]);

  const {
    popupOffers,
    currentPopupDisplayed,
    dailyBonusBadges,
    setPopupOffers,
    setCurrentPopupDisplayed,
    setBadgeTimerLabel,
    setDailyBonusBadges,
    setIndexToCollect,
  } = usePopupState();

  useEffect(() => {
    if (!API.getOffersV2.data?.offers) return;

    // Sort and set popup offers by subType and priority
    const sortedPopups = API.getOffersV2.data.offers.popups?.sort((a: any, b: any) => {
      const subTypeOrder = [EPopupSubType.POST_PURCHASE, EPopupSubType.DAILY_BONUS];
      const aSubTypeIndex = subTypeOrder.indexOf(a.subType);
      const bSubTypeIndex = subTypeOrder.indexOf(b.subType);

      if (aSubTypeIndex !== bSubTypeIndex) {
        return aSubTypeIndex - bSubTypeIndex;
      }

      // Sort by priority within the same subType
      return a.priority - b.priority;
    });

    setPopupOffers(sortedPopups);

    if (popUpStartHourUTC !== API.getOffersV2?.data?.publisherSettings?.popUpStartHourUTC) {
      setPopUpStartHourUTC(API.getOffersV2?.data?.publisherSettings?.popUpStartHourUTC);
    }
  }, [API.getOffersV2.data]);

  useEffect(() => {
    const popupToDisplay = popupOffers?.find(
      (popup: OfferData) =>
        popup.subType === EPopupSubType.POST_PURCHASE ||
        (popup.isAllowedToCollect && popup.indexToCollect)
    );
    setCurrentPopupDisplayed(popupToDisplay || null);

    if (popupToDisplay) {
      reportPopupImpression(popupToDisplay);
      if (popupToDisplay?.indexToCollect) setIndexToCollect(popupToDisplay?.indexToCollect - 1);
    }
  }, [popupOffers]);

  useEffect(() => {
    const newBadges = popupOffers
      ?.filter((popup: OfferData) => {
        const isExistBadge = dailyBonusBadges?.some((badge) => badge.offerId === popup.offerId);
        const isExistBadgeCoverImage = popup?.offerUi?.badgeCoverImage;
        return (
          !popup.isAllowedToCollect &&
          !isExistBadge &&
          popup.subType === EPopupSubType.DAILY_BONUS &&
          isExistBadgeCoverImage
        );
      })
      .map((badge) => ({
        offerId: badge?.offerId,
        font: publisherMetaData.storeTheme.general.font,
        badgeCoverImage: badge?.offerUi?.badgeCoverImage,
        borderColor: badge?.offerUi?.borderColor || {},
        showEvery: badge?.showEvery,
      }));

    if (newBadges && newBadges.length > 0) {
      const prevDailyBadges = dailyBonusBadges || [];
      setDailyBonusBadges([...prevDailyBadges, ...newBadges]);
    }
    if (popUpStartHourUTC === undefined) return;
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setUTCHours(popUpStartHourUTC, 0, 0);
    // Check if the hour is before the current hour, if so, move to the next day
    if (nextDate.getTime() < currentDate.getTime()) {
      nextDate.setUTCDate(nextDate.getUTCDate() + 1);
    }
    setNextAvailableDate(nextDate);
  }, [currentPopupDisplayed, popUpStartHourUTC, popupOffers]);

  useEffect(() => {
    const dailyBonusBadgesLength = dailyBonusBadges?.length || 0;
    let interval: NodeJS.Timeout | undefined;
    if (dailyBonusBadgesLength > 0 && dailyBonusBadges) {
      interval = calculateTimeLeft(dailyBonusBadges[dailyBonusBadgesLength - 1]);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dailyBonusBadges, nextAvailableDate]);

  const assignNextPopUp = () => {
    if (!popupOffers || popupOffers.length <= 0) {
      setCurrentPopupDisplayed(null);
      return;
    }

    const eligibleOffer = popupOffers.find(
      (offer) =>
        !clickedClosePopups.includes(offer.offerId) &&
        currentPopupDisplayed?.offerId !== offer.offerId
    );

    if (eligibleOffer) {
      reportPopupImpression(eligibleOffer);
      if (eligibleOffer?.indexToCollect) setIndexToCollect(eligibleOffer.indexToCollect - 1);
      setCurrentPopupDisplayed(eligibleOffer);
    }
    API.getOffersV2.refetch();
  };

  const calculateNextDate = (popupInterval: dailyBonusBadgeProps): Date => {
    const showEvery = popupInterval?.showEvery;
    const nextDate = nextAvailableDate || new Date();

    if (showEvery && showEvery < 1440) {
      const currentDate = new Date();
      return new Date(currentDate.getTime() + showEvery * 60000);
    }
    return nextDate;
  };

  const calculateTimeLeft = (popupInterval: dailyBonusBadgeProps): NodeJS.Timeout | undefined => {
    const nextDate = calculateNextDate(popupInterval);

    if (!nextDate) return;
    const interval = setInterval(() => {
      const remainingTime = nextDate.getTime() - new Date().getTime();

      if (remainingTime < TIME_LEFT_INTERVAL) {
        clearInterval(interval);
        setBadgeTimerLabel('');
        API.getOffersV2.refetch();
      } else {
        setBadgeTimerLabel(getTimeLabel(remainingTime));
      }
    }, TIME_LEFT_INTERVAL);
    return interval;
  };

  const reportPopupImpression = (offer: OfferData) => {
    if (popupsPresented.includes(offer.offerId)) return;
    setPopupsPresented([...popupsPresented, offer.offerId]);
    API.sendPublisherWebhookEvent.mutate({
      eventType: EPublisherWebhookEventType.OFFER,
      data: {
        eventName: EPublisherWebhookOfferEventType.OFFERS_SHOWN,
        offers: extractOfferMainDetails([offer]),
        appVersion: getAppVersion(),
      },
    });
  };

  return (
    <>
      {!showPaymentModal && !publisherMetaData.featureFlags.store_deeplinks_disable_store && (
        <PopUpsHandler
          setProcessing={setProcessing}
          onPurchaseClick={selectOffer}
          assignNextPopUp={assignNextPopUp}
          setClickedClosePopups={setClickedClosePopups}
          nextAvailableDate={nextAvailableDate}
          dailyBonusRef={dailyBonusRef}
          postPurchaseRef={postPurchaseRef}
        />
      )}
    </>
  );
};
