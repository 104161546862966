import { dailyBonusBadgeProps } from 'components/HeaderWrapper/HeaderWrapper.types';
import { OfferData } from 'constants/apiResponses.types';
import { Observable } from 'state/observable/observable.state.service';

class PopupStateService {
  readonly popupOffers = new Observable<OfferData[]>([]);
  readonly currentPopupDisplayed = new Observable<OfferData | null>(null);
  readonly badgeTimerLabel = new Observable<string>('');
  readonly dailyBonusBadges = new Observable<dailyBonusBadgeProps[]>([]);
  readonly indexToCollect = new Observable<number>(0);
  readonly displayPostPurchase = new Observable<boolean>(false);

  clear = () => {
    this.popupOffers.set([]);
    this.currentPopupDisplayed.set(null);
    this.badgeTimerLabel.set('');
    this.dailyBonusBadges.set([]);
    this.indexToCollect.set(0);
    this.displayPostPurchase.set(false);
  };

  setPopupOffers = (offers: OfferData[]) => this.popupOffers.set(offers);
  setCurrentPopupDisplayed = (current: OfferData | null) => this.currentPopupDisplayed.set(current);
  setBadgeTimerLabel = (timerLabel: string) => this.badgeTimerLabel.set(timerLabel);
  setDailyBonusBadges = (badges: dailyBonusBadgeProps[]) => this.dailyBonusBadges.set(badges);
  setIndexToCollect = (index: number) => this.indexToCollect.set(index);
  setDisplayPostPurchase = (display: boolean) => this.displayPostPurchase.set(display);
}

export const popupStateService = new PopupStateService();
