import { Box, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import newCheckoutConfetti from 'assets/animations/new-checkout-confetti.json';
import React, { useEffect, useState } from 'react';
import useApi from 'hooks/useApi';
import { BootResponse, StoreThemeResponse } from 'constants/apiResponses.types';
import OrderSet from 'components/order-set/order-set';
import useScript from 'hooks/useScript';
import useCustomEvents from 'hooks/useCustomEvents';
import {
  ECheckoutPageEvent,
  EEventsType,
  EResultOptions,
  EStorePhase,
  ESupportType,
} from 'constants/enums';
import BackToGameButton from 'components/BackToGameButton/BackToGameButton';
import { getReturnToGameLinkAddress } from 'components/BackToGameButton/BackToGameButton.utils';
import { useTranslation } from 'react-i18next';
import { getPlatformData } from 'utils';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';
import { StyledTextContainer } from './style';

enum PageStatus {
  COMPLETE = 'completedScreen',
  LOADER = 'loaderScreen',
}

interface ISuccessOrderProps {
  orderId: string;
  currencyCode: string;
}

export const SuccessOrder: React.FC<ISuccessOrderProps> = ({ orderId, currencyCode }) => {
  const { t } = useTranslation();
  const API = useApi({ orderId: orderId });
  const orders = API.getOrder;
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const customEvents = useCustomEvents();
  const scripts = useScript();
  const theme = publisherMetaData?.storeTheme as StoreThemeResponse;
  const [returnToGameLinkAddress, setReturnToGameLinkAddress] = useState<string>();
  const navigate = useNavigate();
  const [animationDone, setAnimationDone] = useState(false);
  const referenceSize = '12px';

  useEffect(() => {
    scripts.injectScript(publisherMetaData.supportConfiguration?.postLoginSnippet);
  }, [publisherMetaData]);

  useEffect(() => {
    customEvents.sendCustomEvent(
      EEventsType.COMPLETE_SCREEN_PRESENTED,
      {
        status: EResultOptions.SUCCESS,
        currency_code: currencyCode,
        platform: getPlatformData(),
        order_id: orderId,
      },
      EStorePhase.POST_LOGIN
    );
  }, []);

  const backToStore = () => {
    const eventData = {
      event: ECheckoutPageEvent.BACK_TO_STORE,
      params: { isOrderSucceed: true },
    };
    window.postMessage(eventData, '*');
  };

  useEffect(() => {
    const deepLinks = publisherMetaData?.integration.deepLinks;
    if (deepLinks.length > 0) {
      const backToGameLink = getReturnToGameLinkAddress(deepLinks);
      setReturnToGameLinkAddress(backToGameLink);
    }
  }, [setReturnToGameLinkAddress, publisherMetaData]);

  const isBackToGameBtnShown =
    publisherMetaData?.integration.backToGameButtonOn &&
    publisherMetaData.storeTheme.completedScreen.backToGameButtonText &&
    returnToGameLinkAddress;

  const contactSupportClicked = () => {
    customEvents.sendCustomEvent(
      EEventsType.SUPPORT_FORM_OPEN,
      {
        phase: EStorePhase.POST_ORDER,
        order_id: orderId,
      },
      EStorePhase.POST_LOGIN
    );
    if (publisherMetaData.supportConfiguration?.postLoginSnippet) {
      API.sendEmptySupport(
        publisherMetaData.storeTheme.publisherId,
        ESupportType.POST_LOGIN,
        EStorePhase.POST_ORDER,
        orderId
      );
    } else {
      orders.remove();
      navigate(`/support/${ESupportType.POST_LOGIN}/${EStorePhase.POST_LOGIN}`);
    }
  };

  return (
    <div className={'checkout-completion'}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <div className={'checkout-completion__close-icon'}>
          <IconButton onClick={backToStore} size="small">
            <CloseIcon style={{ fontSize: 40, color: 'white' }} />
          </IconButton>
        </div>
        {!animationDone && (
          <Lottie
            className="confetti-animation"
            animationData={newCheckoutConfetti}
            loop={false}
            onComplete={() => setAnimationDone(true)}
            style={{
              position: 'absolute',
            }}
          />
        )}
        <StyledTextContainer
          headerColor={theme[PageStatus.COMPLETE].headerColor}
          headerSize={theme[PageStatus.COMPLETE].headerSize}
          headerWeight={theme[PageStatus.COMPLETE].headerWeight}
          textColor={theme[PageStatus.COMPLETE].textColor}
          textSize={theme[PageStatus.COMPLETE].textSize}
          textWeight={theme[PageStatus.COMPLETE].textWeight}
          className={'checkout-completion__text-container'}
        >
          <h1
            className={'checkout-completion__text-container__header'}
            data-testid="storeResolveHeader"
          >
            {theme[PageStatus.COMPLETE].headerText}
          </h1>
          <h2
            className={'checkout-completion__text-container__sub-header'}
            data-testid="storeResolveDesc"
          >
            {[theme[PageStatus.COMPLETE].text]}
          </h2>
        </StyledTextContainer>
        <OrderSet products={orders.data.offer.products} showBackground={false} />
        {isBackToGameBtnShown && (
          <BackToGameButton
            useNewCheckout={true}
            btnText={publisherMetaData.storeTheme.completedScreen.backToGameButtonText}
            btnColor={publisherMetaData.storeTheme.general.buttonColor}
            width={'100%'}
            borderRadius={'4px'}
            returnToGameLinkAddress={returnToGameLinkAddress}
          />
        )}
        <Box mt={2}>
          <Button
            variant="text"
            id="support"
            fullWidth
            sx={{
              color: 'white',
              fontSize: '12px',
              padding: '20px 0',
            }}
            onClick={contactSupportClicked}
          >
            {t('contactSupport')}
          </Button>
        </Box>
        <div
          style={{
            color: publisherMetaData.storeTheme.completedScreen.textColor,
            fontSize: referenceSize,
            fontWeight: 400,
          }}
          className="fadein"
        >
          <span>{t('paymentReferenceID')}</span>
          <br />
          <span>{orderId}</span>
        </div>
      </Box>
    </div>
  );
};
