import { Background } from '@appcharge/shared-ui';
import useApi from '../../hooks/useApi';
import { BootResponse } from '../../constants/apiResponses.types';
import './style.scss';
import { Stack, Typography } from '@mui/material';

const MaintenancePage = () => {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  return (
    <Background
      backgroundImageMobile={publisherMetaData.storeTheme.general.backgroundImageMobile}
      backgroundImageDesktop={publisherMetaData.storeTheme.general.backgroundImageDesktop}
    >
      <Stack alignItems="center" justifyContent="center" height="100vh" p={4} gap={3}>
        <img
          src={publisherMetaData?.storeTheme?.general?.logo}
          alt="logo"
          style={{
            objectFit: 'contain',
          }}
        />
        <Typography
          variant="h5"
          color="white"
          textAlign="center"
          fontFamily={publisherMetaData?.storeTheme?.general?.font || 'Arial'}
        >
          <>{'The store is currently under maintenance, '}</>
          <br />
          <>{'please check back later.'}</>
        </Typography>
      </Stack>
    </Background>
  );
};

export default MaintenancePage;
