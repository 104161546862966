import { useState, useEffect } from 'react';
import useApi from 'hooks/useApi';
import { EEventsType, EStorePhase } from 'constants/enums';
import { OfferData } from 'constants/apiResponses.types';
import { collectImagesFromObject } from 'utils';
import useCustomEvents from 'hooks/useCustomEvents';

export const useLoadImages = () => {
  const customEvents = useCustomEvents();
  const API = useApi({});
  const [initialImagesLoadingTime, setInitialImagesLoadingTime] = useState<number>();
  const [arrOffersImages, setArrOffersImages] = useState<string[]>();
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  const finishBundlesLoad = () => {
    setAllImagesLoaded(true);

    if (initialImagesLoadingTime) {
      const now = Date.now();

      const loadingTimeInMs = now - initialImagesLoadingTime;

      customEvents.sendCustomEvent(
        EEventsType.OFFERS_IMAGES_LOADED,
        {
          offers:
            API.getOffersV2.data.offers.bundles?.length +
            API.getOffersV2.data.offers.promotions?.length +
            API.getOffersV2.data.offers.popups?.length,
          loadingTime: loadingTimeInMs,
          images: arrOffersImages,
        },
        EStorePhase.POST_LOGIN
      );
    }
  };

  useEffect(() => {
    if (!API.getOffersV2?.data?.offers) return;
    let loadedImagesCount = 0;

    const allImagesSet: Set<string> = new Set();

    API.getOffersV2?.data.offers?.bundles?.forEach((bundle: OfferData) => {
      collectImagesFromObject(bundle, allImagesSet);
    });
    API.getOffersV2?.data.offers?.promotions?.forEach((promotion: OfferData) => {
      collectImagesFromObject(promotion, allImagesSet);
    });
    API.getOffersV2?.data.offers?.popups?.forEach((popup: OfferData) => {
      collectImagesFromObject(popup, allImagesSet);
    });

    const allImagesArray = Array.from(allImagesSet);

    const isOnlyNoImage =
      allImagesArray.length === 0 ||
      (allImagesArray.length === 1 && allImagesArray[0] === 'noImage');

    if (isOnlyNoImage) {
      finishBundlesLoad();
      return;
    }
    const arrImages = allImagesArray.filter((curr) => curr !== 'noImage');
    setAllImagesLoaded(false);
    setArrOffersImages(arrImages);

    arrImages.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedImagesCount += 1;
        if (loadedImagesCount + 2 == arrImages.length || arrImages.length <= 2) {
          finishBundlesLoad();
        }
      };
      img.onerror = () => {
        customEvents.sendCustomEvent(
          EEventsType.OFFERS_IMAGE_FAILED_LOADING,
          {
            offers:
              API.getOffersV2.data.offers.bundles?.length +
              API.getOffersV2.data.offers.promotions?.length +
              API.getOffersV2.data.offers.popups?.length,
            images: arrImages,
            image: img.src,
          },
          EStorePhase.POST_LOGIN
        );
        console.error('Failed to load:', img.src);
      };
    });
  }, [API.getOffersV2?.data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      finishBundlesLoad();
    }, 10000);

    const now = Date.now();
    setInitialImagesLoadingTime(now);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  return {
    allImagesLoaded,
  };
};
