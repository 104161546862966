import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledLoginTermsBlock } from '../style';
import { useLoginPage } from '../useLoginPage.hook';
import { useLoginState } from 'state/hooks/login.state.hook';

interface ILoginTermsBlockProps {
  textColor?: string;
  textSize?: string;
  privacyPolicyLink?: string;
  termsAndConditionsLink?: string;
}

export const LoginTermsBlock: React.FC<ILoginTermsBlockProps> = ({
  textColor = 'black',
  textSize = '16px',
  privacyPolicyLink,
  termsAndConditionsLink,
}) => {
  const { publisherMetaData } = useLoginPage();
  const { openInfoModal } = useLoginState();
  const { t } = useTranslation();
  const openInNewTab = publisherMetaData?.storeTheme?.login?.openLinksInNewTab || false;

  return (
    <StyledLoginTermsBlock textColor={textColor} textSize={textSize} className={'login-terms-text'}>
      <span data-testid="login-page-terms-block" className="terms-wrapper">
        {t('login.byLoggingIn')}{' '}
        <span
          onClick={() => {
            openInNewTab
              ? window.open(privacyPolicyLink, '_blank')
              : openInfoModal(privacyPolicyLink);
          }}
        >
          {t('login.privacyPolicy')}
        </span>{' '}
        {t('login.and')}{' '}
        <span
          onClick={() => {
            openInNewTab
              ? window.open(termsAndConditionsLink, '_blank')
              : openInfoModal(termsAndConditionsLink);
          }}
        >
          {t('login.termsAndConditions')}
        </span>
      </span>
    </StyledLoginTermsBlock>
  );
};
