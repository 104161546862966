import React from 'react';
import { Overlay, AcCircularLoader } from '@appcharge/shared-ui';
import { useTranslation } from 'react-i18next';


export const Loader: React.FC = () => {
  const { t } = useTranslation();
  return (
     <Overlay overlayPercentage={90} zIndex={1500}>
        <AcCircularLoader text={t('processing')} />
     </Overlay>
  );
};

