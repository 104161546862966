import { useMemo } from 'react';
import { ProvidersModeProps } from '../types';
import { isDesktop } from 'react-device-detect';
import { ELoginMethods, ELoginMode, EOTPTextModel } from 'constants/enums';
import { FacebookLogin } from '../Externals/Facebook';
import { GoogleLogin } from '../Externals/Google';
import { LoginButton } from '@appcharge/shared-ui';
import { useTranslation } from 'react-i18next';
import { useLoginPage } from '../useLoginPage.hook';
import { useLoginState } from 'state/hooks/login.state.hook';
import { AppleLoginButton } from '../Externals/Apple';

export const ProvidersMode: React.FC<ProvidersModeProps> = ({ loginButtonClickWrapper }) => {
  const { publisherMetaData, isWebview } = useLoginPage();
  const { setMode } = useLoginState();
  const { t } = useTranslation();

  const playerAuthData = publisherMetaData.integration.playersAuthentication;

  const { otpButton } = publisherMetaData.storeTheme.login;

  const showOtpButton = useMemo(
    () =>
      playerAuthData.otpOn &&
      (isDesktop
        ? playerAuthData?.otpTextModel && playerAuthData?.otpTextModel !== EOTPTextModel.MOBILE_ONLY
        : true),
    [playerAuthData]
  );

  return (
    <>
      {showOtpButton && (
        <LoginButton
          textColor={otpButton?.textColor}
          iconSrc={otpButton?.icon}
          iconSize={'32px'}
          backgroundColor={otpButton?.backgroundColor}
          text={otpButton?.text}
          callback={() => loginButtonClickWrapper(() => setMode(ELoginMode.OTP), ELoginMethods.OTP)}
          dataTestId="otp-login-button"
        />
      )}
      {!isWebview && playerAuthData.fbOn && playerAuthData.fbAppId && (
        <FacebookLogin loginButtonClickWrapper={loginButtonClickWrapper} />
      )}
      {!isWebview && playerAuthData.googleOn && playerAuthData.googleAppId && (
        <GoogleLogin loginButtonClickWrapper={loginButtonClickWrapper} />
      )}
      {!isWebview && playerAuthData.appleOn && playerAuthData.appleAppId && (
        <AppleLoginButton loginButtonClickWrapper={loginButtonClickWrapper} />
      )}
      {!isWebview && playerAuthData.usernamePasswordOn && (
        <LoginButton
          variant="transparent"
          text={t('login.loginWithUserName')}
          callback={() =>
            loginButtonClickWrapper(() => setMode(ELoginMode.USERNAME), ELoginMethods.USERNAME)
          }
          dataTestId="username-login-button"
        />
      )}
      {!isWebview && playerAuthData.userTokenOn && (
        <LoginButton
          variant="transparent"
          text={t('login.loginWithPlayerId')}
          callback={() =>
            loginButtonClickWrapper(() => setMode(ELoginMode.TOKEN_ID), ELoginMethods.TOKEN)
          }
          dataTestId="playerId-login-button"
        />
      )}
    </>
  );
};
