import React, { useEffect, useState } from 'react';
import { OfferData } from 'constants/apiResponses.types';
import '../style.scss';
import { EQueryParams } from 'constants/enums';
import useApi from 'hooks/useApi';
import useUrlQuery from 'hooks/useUrlQuery';
import { RollingOffer } from 'components/RollingOffer/RollingOffer';
import { Box } from '@mui/material';

interface IRollingOffersWrapperProps {
  selectOffer: (offer: OfferData) => void;
}

export const RollingOffersWrapper: React.FC<IRollingOffersWrapperProps> = ({ selectOffer }) => {
  const campaignOfferId = useUrlQuery(EQueryParams.OFFER_ID);
  const [rollingOffersList, setRollingOffersList] = useState<OfferData[]>([]);

  const API = useApi({});

  useEffect(() => {
    if (!API.getOffersV2.data?.offers?.rolling || campaignOfferId) return;

    setRollingOffersList(API.getOffersV2.data.offers.rolling);
  }, [API.getOffersV2.data?.offers?.rolling]);

  return (
    <Box className={'rolling-popups-wrapper'}>
      {rollingOffersList.map((rollingOffer: OfferData, i: number) => (
        <RollingOffer key={i} data={rollingOffer} selectOffer={() => selectOffer(rollingOffer)} />
      ))}
    </Box>
  );
};
