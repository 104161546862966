import Slider from 'react-slick';
import { CarouselProps } from './Carousel.types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';
import { ESpecialOfferInternalViewModel } from '../../constants/enums';

const mapTemplateToMaxSlides: Partial<Record<ESpecialOfferInternalViewModel, number>> = {
  [ESpecialOfferInternalViewModel.PIPA]: 1,
  [ESpecialOfferInternalViewModel.LOCO_BINGO]: 2,
};

const mapTemplateToOneBreakpoint: Partial<Record<ESpecialOfferInternalViewModel, number>> = {
  [ESpecialOfferInternalViewModel.LOCO_BINGO]: 767,
};

const getSlidesToShow = ({
  children,
  defaultMaxSlides,
  bundleViewModel,
}: {
  children: CarouselProps['children'];
  defaultMaxSlides: number;
  bundleViewModel?: ESpecialOfferInternalViewModel;
}) => {
  const maxSlides =
    (bundleViewModel && mapTemplateToMaxSlides[bundleViewModel]) || defaultMaxSlides;

  return children.length < maxSlides ? children.length : maxSlides;
};

const Carousel = ({ children, showDivider, bundleViewModel }: CarouselProps) => {
  const settings = {
    dots: bundleViewModel !== ESpecialOfferInternalViewModel.ME2ON,
    infinite: true,
    speed: 500,
    slidesToShow: getSlidesToShow({ children, bundleViewModel, defaultMaxSlides: 3 }),
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: (bundleViewModel && mapTemplateToOneBreakpoint[bundleViewModel]) || 848,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1264,
        settings: {
          slidesToShow: getSlidesToShow({ children, bundleViewModel, defaultMaxSlides: 2 }),
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings} className={bundleViewModel}>
        {children}
      </Slider>
      {!showDivider && <hr className={`carousel-divider carousel-divider-${bundleViewModel}`} />}
    </>
  );
};

export default Carousel;
