import useApi from '../../hooks/useApi';
import { BootResponse } from '../../constants/apiResponses.types';
import { BundlesProps } from './Bundle.types';
import React, { useEffect, useRef, useState } from 'react';
import {
  BagelcodeBundle,
  CandivoreBundle,
  CashmanCasinoBundle,
  ClassicBundle,
  CommunixBundle,
  HeartOfVegasBundle,
  JackpotSlotsBundle,
  LightningLinkBundle,
  LocoBingoBundle,
  MainSubBundle,
  Me2onBundle,
  PipaBundle,
  WhispersBundle,
  BabaBundle,
  PlaySimpleBundle,
  ProductData,
  MightyFuBundle,
  BundleProps,
  Candivore2Bundle,
  Bagelcode2Bundle,
} from '@appcharge/shared-ui';
import { motion, useAnimationControls } from 'framer-motion';
import { EBundlesInternalViewModel, ESaleDiscountDisplayType } from '../../constants/enums';
import useObserver from '../../hooks/useObserver';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../hooks/useEvent';

const bundleDesignDic: Record<EBundlesInternalViewModel, React.FC<BundleProps>> = {
  [EBundlesInternalViewModel.AK]: ClassicBundle,
  [EBundlesInternalViewModel.COMMUNIX]: CommunixBundle,
  [EBundlesInternalViewModel.MATCH]: CandivoreBundle,
  [EBundlesInternalViewModel.CANDIVORE]: Candivore2Bundle,
  [EBundlesInternalViewModel.MAIN_SUB]: MainSubBundle,
  [EBundlesInternalViewModel.BAGELCODE]: BagelcodeBundle,
  [EBundlesInternalViewModel.BAGELCODE2]: Bagelcode2Bundle,
  [EBundlesInternalViewModel.LOCO_BINGO]: LocoBingoBundle,
  [EBundlesInternalViewModel.JACKPOT_SLOTS]: JackpotSlotsBundle,
  [EBundlesInternalViewModel.BIG_FISH_CASINO]: JackpotSlotsBundle,
  [EBundlesInternalViewModel.CASHMAN_CASINO]: CashmanCasinoBundle,
  [EBundlesInternalViewModel.LIGHTNING_LINK_CASINO]: LightningLinkBundle,
  [EBundlesInternalViewModel.HEART_OF_VEGAS]: HeartOfVegasBundle,
  [EBundlesInternalViewModel.ME2ON]: Me2onBundle,
  [EBundlesInternalViewModel.PLAY_SIMPLE]: PlaySimpleBundle,
  [EBundlesInternalViewModel.PIPA]: PipaBundle,
  [EBundlesInternalViewModel.WHISPERS]: WhispersBundle,
  [EBundlesInternalViewModel.BABA]: BabaBundle,
  [EBundlesInternalViewModel.MIGHTY_FU_CASINO]: MightyFuBundle,
};

interface IProductResponse {
  publisherProductId: string;
  productName: string;
  displayName: string;
  images: { type: 'product' | 'productPrefix'; url: string }[];
}

export const Bundle: React.FC<BundlesProps> = ({ data, selectOffer, index, threshold }) => {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const isFocused = API.getOffersV2.data?.offerFocus.bundleId === data.offerId;
  const BundleDesign =
    bundleDesignDic[publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel];
  const bundleRef = useRef<HTMLDivElement>(null);
  const controls = useAnimationControls();
  useObserver(bundleRef.current, data);
  const APPEARANCE_DELAY = 0.15;
  const { t } = useTranslation();
  const { openTooltipEventHandler } = useEvent();
  const calculateDelay = () => {
    if (!threshold) return index * APPEARANCE_DELAY;
    if (index < threshold) return index * APPEARANCE_DELAY;
    return (index - threshold) * APPEARANCE_DELAY;
  };

  // Trigger re-rendering when screen size changing
  const [, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setScreenWidth(window.innerWidth);
      }, 100);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    controls.start({
      y: 0,
      opacity: 1,
    });
  }, [controls]);

  useEffect(() => {
    if (isFocused) {
      setTimeout(() => {
        bundleRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 750);
      const scrollUp = () => {
        controls
          .start({
            scale: 1.05,
            transition: {
              delay: 0,
            },
          })
          .then(() =>
            controls.start({
              scale: 1,
              transition: {
                delay: 0.25,
              },
            })
          );
        document.removeEventListener('scrollend', scrollUp);
      };
      document.addEventListener('scrollend', scrollUp);
    }
  }, [isFocused, controls]);

  const [animateCompleted, setAnimateCompleted] = useState(false);

  const getTooltipImagesSrc = async (arrIds: string[]): Promise<IProductResponse[]> => {
    try {
      const res = await API.getProductsAssets(arrIds);
      return res;
    } catch (e) {
      console.log('error', e);
      return [];
    }
  };

  const mapPropsToBundle: Partial<Record<EBundlesInternalViewModel, any>> = {
    [EBundlesInternalViewModel.LIGHTNING_LINK_CASINO]: {
      tooltipLoadCallback: getTooltipImagesSrc,
    },
    [EBundlesInternalViewModel.MIGHTY_FU_CASINO]: {
      tooltipLoadCallback: getTooltipImagesSrc,
    },
    [EBundlesInternalViewModel.HEART_OF_VEGAS]: {
      tooltipLoadCallback: getTooltipImagesSrc,
    },
    [EBundlesInternalViewModel.CASHMAN_CASINO]: {
      tooltipLoadCallback: getTooltipImagesSrc,
    },
    [EBundlesInternalViewModel.BIG_FISH_CASINO]: {
      gameName: EBundlesInternalViewModel.BIG_FISH_CASINO,
    },
    [EBundlesInternalViewModel.JACKPOT_SLOTS]: {
      gameName: EBundlesInternalViewModel.JACKPOT_SLOTS,
      translations: { includes: t('bundles.subProductsPrefixText') },
    },
  };

  const bundleProps =
    mapPropsToBundle[publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel] || {};

  return (
    <motion.div
      ref={bundleRef}
      className="bundle-top-container"
      data-testid={`${data.offerId}`}
      initial={{ y: 100, opacity: 0 }}
      animate={controls}
      transition={{
        delay: calculateDelay(),
        opacity: {
          duration: 0.25,
          ease: 'easeIn',
        },
        y: {
          duration: 0.35,
          ease: 'easeOut',
        },
        scale: {
          duration: 0.25,
        },
      }}
    >
      <motion.div
        style={{ cursor: 'pointer', height: '100%' }}
        whileHover={{
          scale:
            publisherMetaData?.storeTheme.general.bundlesInternalViewModel ===
            EBundlesInternalViewModel.MAIN_SUB
              ? 1
              : 1.05,
          zIndex: 100,
        }}
        onClick={selectOffer}
        onAnimationComplete={() => setAnimateCompleted(true)}
      >
        <BundleDesign
          loaded={animateCompleted}
          productList={data.productsSequence[0].products.map((p: ProductData) => p)}
          width="100%"
          bundleBorderColor={{
            gradientDirection:
              publisherMetaData.storeTheme.storeScreen.bundleBorderColor.gradientDirection,
            colorOne: publisherMetaData.storeTheme.storeScreen.bundleBorderColor.colorOne,
            colorTwo: publisherMetaData.storeTheme.storeScreen.bundleBorderColor.colorTwo,
          }}
          bundleButtonColor={publisherMetaData.storeTheme.general.buttonColor}
          bundleButtonTextColor={publisherMetaData.storeTheme.general.buttonTextColor}
          backgroundSrc={data.offerUi.backgroundImage}
          price={data.productsSequence[0].price}
          fontFamily={publisherMetaData.storeTheme.general.font}
          badges={data?.badges} // ACDEV-9385: remove old badge schema
          emblemBadges={data?.dynamicOfferUi?.offerBadges?.emblems?.data}
          ribbonBadges={data?.dynamicOfferUi?.offerBadges?.ribbons?.data}
          backgroundColor={data.offerUi.specialOffer?.backgroundColor}
          salePercentage={data.productSale?.sale}
          salePercentageDisplayType={
            data.productSale?.type || ('percentage' as ESaleDiscountDisplayType)
          }
          priceDiscount={data.priceDiscount?.discount}
          priceDiscountDisplayType={
            data.priceDiscount?.type || ('percentage' as ESaleDiscountDisplayType)
          }
          bundleRef={bundleRef}
          availability={data.playerAvailability}
          playerAvailability={data.offerPlayerRemainingAvailability}
          borderRadius={publisherMetaData.storeTheme.storeScreen.borderRadius}
          tooltipOpenEventHandler={openTooltipEventHandler}
          {...bundleProps}
        />
      </motion.div>
    </motion.div>
  );
};
