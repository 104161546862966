import mixpanel, { Dict } from 'mixpanel-browser';
import { localStorageUtil } from 'state/localStorage.state.service';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { ELocalStorageKeys, ESessionStorageKeys, EStorePhase } from '../constants/enums';
// import { clarity } from 'react-microsoft-clarity';

export interface UseCustomEventsReturn {
  sendCustomEvent: (eventType: string, options: Dict, eventPhase?: EStorePhase) => void;
  setDistinctId: () => void;
  resetSession: () => void;
}

export default function useCustomEvents(): UseCustomEventsReturn {
  const { i18n } = useTranslation();
  const publisherMetaData = localStorageUtil.get(ELocalStorageKeys.PUBLISHER_META);

  const sendCustomEvent = (
    eventType: string,
    options: Dict,
    eventPhase: EStorePhase = EStorePhase.OTHER
  ) => {
    // if (!localStorageUtil.get(ELocalStorageKeys.ANALYTICS)) return;
    // if (!publisherMetaData.externalServicesConfig.mixpanelActive) {
    //   return;
    // }
    const playerData = localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA);
    const platform = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';

    options.environment = process.env.REACT_APP_ENV || 'local';
    options.publisher_id = publisherMetaData.storeTheme.publisherId;
    options.URL = window.location.origin;
    options.platform = platform;
    options.language = i18n.language;
    options.store_version = process.env.REACT_APP_STORE_VERSION;
    options.shared_ui_version = process.env.REACT_APP_SHARED_UI_VERSION;
    options.checkout_sdk_version = process.env.REACT_APP_CHECKOUT_SDK_VERSION;

    // clarity.setTag('playerId', playerData?.playerId);

    if (eventPhase === EStorePhase.POST_LOGIN) {
      if (playerData) {
        options.player_id = playerData.playerId;
        options.player_country = playerData.playerCountry;
      }
      const sessionData = localStorageUtil.get(ESessionStorageKeys.SESSION_DATA);
      if (sessionData?.id) {
        options.session_id = sessionData.id;
      }
    }
    if (options.environment === 'production') mixpanel.track(eventType, options);
  };

  const setDistinctId = () => {
    const playerData = localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA);
    mixpanel.identify(`${publisherMetaData?.storeTheme?.publisherId}_${playerData?.playerId}`);
  };

  const resetSession = () => {
    mixpanel.reset();
  };

  return {
    sendCustomEvent,
    setDistinctId,
    resetSession,
  };
}
