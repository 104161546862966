import { useObservable } from 'state/observable/observable.state.hook';
import { popupStateService } from 'state/popup.state.service';

export const usePopupState = () => {
  return {
    popupOffers: useObservable(popupStateService.popupOffers),
    currentPopupDisplayed: useObservable(popupStateService.currentPopupDisplayed),
    badgeTimerLabel: useObservable(popupStateService.badgeTimerLabel),
    dailyBonusBadges: useObservable(popupStateService.dailyBonusBadges),
    indexToCollect: useObservable(popupStateService.indexToCollect),
    displayPostPurchase: useObservable(popupStateService.displayPostPurchase),
    setPopupOffers: popupStateService.setPopupOffers,
    setCurrentPopupDisplayed: popupStateService.setCurrentPopupDisplayed,
    setBadgeTimerLabel: popupStateService.setBadgeTimerLabel,
    setDailyBonusBadges: popupStateService.setDailyBonusBadges,
    setIndexToCollect: popupStateService.setIndexToCollect,
    setDisplayPostPurchase: popupStateService.setDisplayPostPurchase,
    clear: popupStateService.clear,
  };
};
