import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useApi from '../../hooks/useApi';
import {
  EBalancesTheme,
  Header,
  HeaderProps,
  ProductMadnessHeader,
  WhispersHeader,
} from '@appcharge/shared-ui';
import { BootResponse } from '../../constants/apiResponses.types';
import { HeaderWrapperProps } from './HeaderWrapper.types';
import { EBundlesInternalViewModel, ELocalStorageKeys } from '../../constants/enums';
import { EPublisher, featureFlagsUtil } from '../../utils';
import { localStorageUtil } from 'state/localStorage.state.service';

const ProductMadnessHeaderTemplate = ({
  profilePicUrl,
  playerName,
  logoPicUrl,
  openLogoutModal,
  overrideStore,
  balances,
  balanceQuantityFormat,
  dailyBonusBadges,
}: HeaderProps) => {
  const { t } = useTranslation();
  return (
    <ProductMadnessHeader
      profilePicUrl={profilePicUrl}
      playerName={playerName}
      logoPicUrl={logoPicUrl}
      openLogoutModal={openLogoutModal}
      overrideStore={overrideStore}
      balances={balances}
      balanceQuantityFormat={balanceQuantityFormat}
      dailyBonusBadges={dailyBonusBadges}
      timer={''}
      translations={{ yourBalance: t('balances.balancePrefixText') }}
    />
  );
};

const mapHeaderByTemplate: Partial<Record<EBundlesInternalViewModel, any>> = {
  [EBundlesInternalViewModel.JACKPOT_SLOTS]: ProductMadnessHeaderTemplate,
  [EBundlesInternalViewModel.BIG_FISH_CASINO]: ProductMadnessHeaderTemplate,
  [EBundlesInternalViewModel.WHISPERS]: WhispersHeader,
};

export const HeaderWrapper: React.FC<HeaderWrapperProps> = ({
  openLogoutModal,
  logoSize,
  playerName,
  balances,
  fontFamily,
  showHeader,
  balanceQuantityFormat,
  profileFrameImg,
  playerLevelImg,
}) => {
  const [shouldShowPlayerName, setShouldShowPlayerName] = useState(false);

  const pic =
    localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)?.profileImageUrl ||
    'https://media.appcharge.com/defaults/profile.png';
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const balanceTheme = publisherMetaData?.storeTheme.general?.balancesTheme
    ? publisherMetaData.storeTheme.general.balancesTheme
    : featureFlagsUtil.isPublisher(EPublisher.HUUUGE)
      ? EBalancesTheme.DEFAULT
      : featureFlagsUtil.isPublisher(EPublisher.BAGELCODE)
        ? EBalancesTheme.BAGELCODE
        : featureFlagsUtil.isPublisher(EPublisher.SPACEGO)
          ? EBalancesTheme.SPACEGO
          : featureFlagsUtil.isPublisher(EPublisher.PIPA)
            ? EBalancesTheme.PIPA
            : undefined;

  const bundlesInternalViewModel = publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel;
  const HeaderTemplate = useMemo(
    () => mapHeaderByTemplate[bundlesInternalViewModel] || Header,
    [bundlesInternalViewModel]
  );

  useEffect(() => {
    const hasPlayerName: boolean =
      [
        EBundlesInternalViewModel.CASHMAN_CASINO,
        EBundlesInternalViewModel.HEART_OF_VEGAS,
        EBundlesInternalViewModel.LIGHTNING_LINK_CASINO,
        EBundlesInternalViewModel.MIGHTY_FU_CASINO,
        EBundlesInternalViewModel.PIPA,
        EBundlesInternalViewModel.BIG_FISH_CASINO,
        EBundlesInternalViewModel.JACKPOT_SLOTS,
        EBundlesInternalViewModel.LOCO_BINGO,
      ].includes(bundlesInternalViewModel) || featureFlagsUtil.isPublisher(EPublisher.HUUUGE);
    setShouldShowPlayerName(hasPlayerName);
  }, [publisherMetaData?.storeTheme?.general]);

  const overrideStore = () => {
    const subDomain = window.prompt('?');
    localStorageUtil.set(ELocalStorageKeys.OVERRIDE_STORE, `${subDomain}.appcharge.com`);
  };

  return (
    <HeaderTemplate
      profilePicUrl={pic}
      profilePicBorder={publisherMetaData.storeTheme.storeScreen.bundleBorderColor}
      playerName={shouldShowPlayerName ? playerName : ''}
      openLogoutModal={openLogoutModal}
      logoSize={logoSize}
      logoPicUrl={publisherMetaData.storeTheme.general.logo}
      overrideStore={overrideStore}
      balances={balances}
      showHeader={showHeader}
      fontFamily={fontFamily}
      balanceQuantityFormat={balanceQuantityFormat}
      balanceTheme={balanceTheme}
      profileFrameImg={profileFrameImg}
      backgroundColor={
        balanceTheme === EBalancesTheme.PLAY_SIMPLE ? 'rgb(0,0,0, 0.6)' : 'transparent'
      }
      fullWidth={balanceTheme === EBalancesTheme.PLAY_SIMPLE}
      playerLevelImg={playerLevelImg}
      fontWeight={bundlesInternalViewModel === EBundlesInternalViewModel.BABA ? '400' : undefined}
    />
  );
};
