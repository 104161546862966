import { OfferData } from 'constants/apiResponses.types';
import { Observable } from './observable';

class AppStateService {
  readonly displayLoader = new Observable<boolean>(true);
  readonly selectedOffer = new Observable<OfferData | null>();

  clear = () => {
    this.displayLoader.set(false);
    this.selectedOffer.set(null);
  };

  setDisplayLoader = (showLoader: boolean) => this.displayLoader.set(showLoader);
  setSelectedOffer = (offer: OfferData | null) => this.selectedOffer.set(offer);
}

export const appStateService = new AppStateService();
