import { isAndroid, isIOS, isSafari } from 'react-device-detect';
import { EPlatformType, ESessionStorageKeys } from '../../constants/enums';
import UAParser from 'ua-parser-js';
import { localStorageUtil } from 'state/localStorage.state.service';

const isWebviewBrowser = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    userAgent.indexOf('instagram') > -1 ||
    userAgent.indexOf('fban') > -1 ||
    userAgent.indexOf('fbav') > -1 ||
    userAgent.indexOf('fb_iab') > -1 ||
    userAgent.indexOf('messenger') > -1
  );
};

const checkIfOlderOsVersions = (): boolean => {
  const ua = navigator?.userAgent?.toLowerCase();
  if (!ua) return true;

  const isChromeIOS = /crios/.test(ua);

  let versionMatch = ua.match(/version\/(\d+(\.\d+)?)/);

  if (!versionMatch) {
    versionMatch = ua.match(/crios\/(\d+(\.\d+)?)/); // Find version for Chrome
  }

  if (!versionMatch) return false; // Unable to extract version, consider it older

  const osMobileVersion = parseFloat(versionMatch[1]);
  if (isNaN(osMobileVersion)) return true; // Parsing failed, consider it older

  const SAFARI_IOS_THRESHOLD = 14.5;
  const CHROME_IOS_THRESHOLD = 92.1;
  const ANDROID_THRESHOLD = 123;

  return (
    ((isSafari || isIOS) && osMobileVersion < SAFARI_IOS_THRESHOLD) ||
    (isAndroid && osMobileVersion < ANDROID_THRESHOLD) ||
    (isChromeIOS && osMobileVersion < CHROME_IOS_THRESHOLD)
  );
};

const getLoginEventsData = ({
  method,
  options,
}: {
  method?: any;
  options?: Record<string, string>;
}) => {
  const parser = new UAParser();
  const source = sessionStorage.getItem('source');

  const info = parser.getResult();
  const sessionData = localStorageUtil.get(ESessionStorageKeys.SESSION_DATA);
  const platform = isIOS
    ? EPlatformType.IOS
    : isAndroid
      ? EPlatformType.ANDROID
      : EPlatformType.WEB;
  const data: any = {
    sessionId: sessionData?.id,
    device_version: info.device.model,
    vendor: info.device.vendor,
    os_version: info.os.version,
    platform,
    source,
    method
  };

  return options ? { ...data, ...options } : data;
};

export const loginPageService = {
  isWebviewBrowser,
  checkIfOlderOsVersions,
  getLoginEventsData,
};
