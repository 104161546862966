import { ELocalStorageKeys, ESessionStorageKeys, EStorageType } from 'constants/enums';

class LocalStorageStateService {
  private sessionStorageKeys: string[] = Object.values(ESessionStorageKeys);
  private localStorageKeys: string[] = Object.values(ELocalStorageKeys);

  getStorage = (key: ELocalStorageKeys | ESessionStorageKeys): EStorageType => {
    const isSession = this.sessionStorageKeys.includes(key);
    const isLocal = this.localStorageKeys.includes(key);
    if (!isSession && !isLocal) throw new Error("storage key isn't defined");

    return isSession ? EStorageType.sessionStorage : EStorageType.localStorage;
  };

  set = (key: ELocalStorageKeys | ESessionStorageKeys, value: any) => {
    const storageKey: EStorageType = this.getStorage(key);
    (window as Window)[storageKey].setItem(key, JSON.stringify(value));
  };

  getAny = (key: ELocalStorageKeys | ESessionStorageKeys) => {
    return window.localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key)!)
      : window.sessionStorage.getItem(key)
        ? JSON.parse(window.sessionStorage.getItem(key)!)
        : null;
  };

  get = (key: ELocalStorageKeys | ESessionStorageKeys) => {
    const storageKey: EStorageType = this.getStorage(key);

    return (
      (window as Window)[storageKey].getItem(key) && JSON.parse(window[storageKey].getItem(key)!)
    );
  };

  remove = (key: ELocalStorageKeys | ESessionStorageKeys) => {
    const storageKey: EStorageType = this.getStorage(key);

    return (window as Window)[storageKey].removeItem(key);
  };

  clear = () => {
    const overrideStore = this.get(ELocalStorageKeys.OVERRIDE_STORE);
    const bootApiOverride = this.get(ELocalStorageKeys.BOOT_API_OVERRIDE);
    const offerApiOverride = this.get(ELocalStorageKeys.OFFER_API_OVERRIDE);
    const postLoginApiOverride = this.get(ELocalStorageKeys.POST_LOGIN_API_OVERRIDE);
    const analytics = this.get(ELocalStorageKeys.ANALYTICS);
    const publisherMeta = this.get(ELocalStorageKeys.PUBLISHER_META);
    const initialLanguage = this.get(ELocalStorageKeys.CURRENT_LANGUAGE);
    localStorage.clear();
    this.set(ELocalStorageKeys.OVERRIDE_STORE, overrideStore);
    this.set(ELocalStorageKeys.ANALYTICS, analytics);
    this.set(ELocalStorageKeys.PUBLISHER_META, publisherMeta);
    this.set(ELocalStorageKeys.BOOT_API_OVERRIDE, bootApiOverride);
    this.set(ELocalStorageKeys.OFFER_API_OVERRIDE, offerApiOverride);
    this.set(ELocalStorageKeys.POST_LOGIN_API_OVERRIDE, postLoginApiOverride);
    this.set(ELocalStorageKeys.CURRENT_LANGUAGE, initialLanguage);
  };
}

export const localStorageUtil = new LocalStorageStateService();
