import { GoogleOAuthProvider } from '@react-oauth/google';
import { StyledImageContainer, StyledLoginPage } from '../style';
import { Box, Button } from '@mui/material';
import { LoginTermsBlock } from './TermsBlock';
import { ProductMadnessLogin } from '../Custom/ProductMadness/ProductMadnessLogin';
import React from 'react';
import { ILoginProps } from '../types';
import { useTranslation } from 'react-i18next';
import useLoginEvents from '../../../hooks/useLoginEvents';
import {
  EEventsType,
  ELoginEvents,
  ELoginMethods,
  ELoginMode,
  EOTPTextModel,
  EStorePhase,
} from '../../../constants/enums';
import { isDesktop } from 'react-device-detect';
import { WebviewEscapeScreen } from '../WebviewEscapeScreen/WebviewEscapeScreen';
import { LoginButtonBlocks } from './ButtonBlocks';
import { useLoginPage } from '../useLoginPage.hook';
import useCustomEvents from 'hooks/useCustomEvents';
import { loginPageService } from '../login-page.service';
import { useLoginState } from 'state/hooks/login.state.hook';

export const Login: React.FC<ILoginProps> = (props: ILoginProps) => {
  const { mode } = props;
  const { sendPublisherWebhookLoginEvent } = useLoginEvents();
  const customEvents = useCustomEvents();
  const { publisherMetaData, isWebview, onContactSupport, isProductMadness } = useLoginPage();
  const { setMode } = useLoginState();
  const { t } = useTranslation();

  const {
    loginLogoSize,
    textSize = 24,
    textColor = 'blue',
    text = 'Welcome to appcharge store!',
    textWeight = 'normal',
  } = publisherMetaData.storeTheme.login;
  const logo =
    publisherMetaData.storeTheme.login?.loginLogoImage ||
    publisherMetaData.storeTheme.general?.logo ||
    'https://media.appcharge.com/defaults/logo.svg';
  const playerAuthData = publisherMetaData.integration.playersAuthentication;
  const fontFamily =
    publisherMetaData.storeTheme.login.font || publisherMetaData.storeTheme.general.font;
  const { privacyPolicyUrl, termsAndConditionsUrl } = publisherMetaData.publisher;
  const showOtpButton =
    playerAuthData.otpOn &&
    (isDesktop
      ? playerAuthData?.otpTextModel && playerAuthData?.otpTextModel !== EOTPTextModel.MOBILE_ONLY
      : true);

  const tranalateLoginModeToLoginMethod = (mode: ELoginMode): ELoginMethods => {
    switch (mode) {
      case ELoginMode.USERNAME:
        return ELoginMethods.USERNAME;
      case ELoginMode.TOKEN_ID:
        return ELoginMethods.TOKEN;
      case ELoginMode.OTP:
        return ELoginMethods.OTP;
      default:
        return ELoginMethods.GOOGLE;
    }
  };

  const handleBackBtnClicked = async () => {
    customEvents.sendCustomEvent(
      EEventsType.LOGIN_CANCELED,
      loginPageService.getLoginEventsData({}),
      EStorePhase.PRE_LOGIN
    );
    sendPublisherWebhookLoginEvent({
      eventName: ELoginEvents.LOGIN_CANCELED,
      method: tranalateLoginModeToLoginMethod(mode),
    });
    setMode(ELoginMode.PROVIDERS);
  };

  const displayTermsBlock = !isWebview || showOtpButton;

  return (
    <GoogleOAuthProvider clientId={playerAuthData?.googleAppId || ''}>
      {isProductMadness ? (
        <ProductMadnessLogin loginProps={props} handleBackBtnClicked={handleBackBtnClicked} />
      ) : (
        <StyledLoginPage
          fontFamily={fontFamily}
          textColor={textColor}
          textSize={textSize}
          textWeight={textWeight}
        >
          <StyledImageContainer>
            <img
              className="logo"
              id={loginLogoSize}
              alt="game logo"
              src={logo}
              style={{
                width: window.location.hostname.includes('matchmaste.rs') ? '230px' : 'auto',
              }}
            />
          </StyledImageContainer>
          {isWebview && mode === ELoginMode.PROVIDERS ? (
            <>
              <WebviewEscapeScreen />
              {showOtpButton && <div className="orText">{t('login.or')}</div>}
            </>
          ) : (
            <h2 data-testid="loginPageTitle">{text}</h2>
          )}
          <LoginButtonBlocks {...props} />
          {displayTermsBlock && (
            <LoginTermsBlock
              textColor={textColor}
              privacyPolicyLink={privacyPolicyUrl}
              termsAndConditionsLink={termsAndConditionsUrl}
            />
          )}
          {!isWebview && (
            <Box mt={2}>
              <Button
                data-testid="login-page-contact-support-btn"
                variant="text"
                sx={{
                  color: 'white',
                  textTransform: 'none',
                  fontSize: '16px',
                }}
                onClick={() => onContactSupport?.()}
              >
                {t('contactSupport')}
              </Button>
            </Box>
          )}
          {mode !== ELoginMode.PROVIDERS && (
            <button className="backButton" onClick={handleBackBtnClicked}>
              {t('login.back')}
            </button>
          )}
        </StyledLoginPage>
      )}
    </GoogleOAuthProvider>
  );
};
