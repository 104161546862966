import { AppchargeCheckout } from 'appcharge-checkout-reactjs-sdk';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import useApi from 'hooks/useApi';
import { useNavigate } from 'react-router-dom';
import {
  ECheckoutPageEvent,
  EEventsType,
  ELocalStorageKeys,
  EOfferType,
  EStorePhase,
  EOrderStatus,
  EResultOptions,
} from 'constants/enums';
import './style.scss';
import useCustomEvents from 'hooks/useCustomEvents';
import { localStorageUtil } from 'state/localStorage.state.service';
import { useLocalizationState } from 'state/hooks/localization.state.hook';
import { countryToLanguageMap } from 'constants/mapCountryLanguage';
import {
  AppchargeLocale,
  EventParams,
} from 'appcharge-checkout-reactjs-sdk/lib/components/ui/AppchargeCheckout';
import { BootResponse, OfferData } from 'constants/apiResponses.types';
import { Overlay } from '@appcharge/shared-ui';
import { useAppState } from 'state/hooks/app.state.hook';
import { SuccessOrder } from './SuccessOrder';
import { Failed } from 'pages/failed/failed';
import { getPlatformData } from 'utils';
import animationData from 'assets/animations/confetti-animation.json';
import Lottie from 'lottie-react';

interface Session {
  token?: string;
  url?: string;
}

interface CheckoutProduct {
  name: string;
  amount: string;
  sku: string;
}

const MAX_TIMEOUT_OFFERS_REFETCH = 1000 * 60; // 1 minute

export interface CheckoutProps {
  close: () => void;
  selectedOffer: OfferData | string;
  collectId?: string;
  setProcessing: (value: boolean) => void;
  setLoadingFromCampaign: (value: boolean) => void;
  currencyCode: string;
  setSelectedOffer: (value: OfferData | null) => void;
}

const Checkout = ({
  close,
  selectedOffer,
  collectId,
  setProcessing,
  setLoadingFromCampaign,
  currencyCode,
  setSelectedOffer,
}: CheckoutProps) => {
  const navigate = useNavigate();
  const [session, setSession] = useState<Session>({});
  const [failedHashValidation, setFailedHashValidation] = useState<boolean>(false);
  const offersFetchInterval = useRef<any>();
  const [isSettled, setIsSettled] = useState<boolean>(!!collectId);
  const [orderId, setOrderId] = useState<string>(collectId || '');
  const [orderResolvedState, setOrderResolvedState] = useState<
    EOrderStatus.CHARGE_SUCCEED | EOrderStatus.CHARGE_FAILED | EOrderStatus.PAYMENT_FAILED
  >();
  const [errorCode, setErrorCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showFailed, setShowFailed] = useState<boolean>(false);
  const [showSuccessOrder, setShowSuccessOrder] = useState<boolean>(false);
  const API = useApi({ orderId });
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const customEvents = useCustomEvents();
  const { languagesList, currentLanguage } = useLocalizationState();
  const { displayLoader, setDisplayLoader } = useAppState();
  const [poolingOrderInterval, setPoolingOrderInterval] = useState<NodeJS.Timeout | null>(null);
  const [showConfetti, setShowConfetti] = useState(false);

  const locale = useMemo(
    () =>
      `${currentLanguage};${
        countryToLanguageMap[
          languagesList?.find(({ language }) => language === currentLanguage)?.country || ''
        ] || ''
      }`,
    [currentLanguage]
  );

  useEffect(() => {
    if (collectId) return;
    setProcessing(true);
    let createSessionData;
    if (typeof selectedOffer !== 'string') {
      createSessionData = {
        offerId: (selectedOffer as OfferData).offerId,
        sequence: (selectedOffer as OfferData).indexToCollect || 0,
        offerJwt: (selectedOffer as OfferData).offerJwt,
      };
    } else {
      createSessionData = {
        offerId: selectedOffer,
        sequence: 0,
      };
    }
    API.createCheckoutSessionV2.mutate(createSessionData, {
      onSuccess: (sessionData) => {
        setSession({
          url: sessionData.data.url,
          token: sessionData.data.checkoutSessionToken,
        });
        document.body.classList.add('screen-lock');
      },
      onError: (error: any) => {
        if (error.response?.status === 410) {
          setFailedHashValidation(true);
          document.body.classList.remove('screen-lock');
          setProcessing(false);
          return;
        }
        navigate('../failed?msg=creating order error');
      },
    });
  }, [selectedOffer]);

  useEffect(
    () => () => {
      clearPoolingInterval();
    },
    []
  );

  const onResolve = (params: Partial<EventParams>) => {
    setOrderId(params.orderId || '');
  };

  const clearPoolingInterval = () => {
    if (poolingOrderInterval !== null) {
      clearInterval(poolingOrderInterval);
      setPoolingOrderInterval(null);
    }
  };

  useEffect(() => {
    if (!orderId || poolingOrderInterval !== null) return;
    const poolingInterval = setInterval(async () => {
      try {
        if (
          ![
            EOrderStatus.CHARGE_SUCCEED,
            EOrderStatus.CHARGE_FAILED,
            EOrderStatus.PAYMENT_FAILED,
          ].includes(API.getOrder.data?.state) &&
          !API.getOrder.isFetching
        ) {
          await API.getOrder.refetch();
        }
      } catch (err) {
        console.error(err);
        setShowFailed(true);
        setErrorCode('storeFailure');
      }
    }, 2000);

    setPoolingOrderInterval(poolingInterval);
  }, [orderId, API.getOrder.data]);

  useEffect(() => {
    const currentState = API.getOrder.data?.state;

    if (
      [
        EOrderStatus.CHARGE_SUCCEED,
        EOrderStatus.CHARGE_FAILED,
        EOrderStatus.PAYMENT_FAILED,
      ].includes(currentState)
    ) {
      setOrderResolvedState(currentState);
      clearPoolingInterval();
    }
  }, [orderId, API.getOrder.data?.state]);

  useEffect(() => {
    if (!orderResolvedState || !orderId) return;
    sendResolvedEvents();

    setProcessing(false);
    if (orderResolvedState === EOrderStatus.CHARGE_SUCCEED) {
      // for free rolling offer, just show confetti
      if (
        typeof selectedOffer !== 'string' &&
        (selectedOffer as OfferData).offerType === EOfferType.ROLLING_OFFER &&
        API.getOrder.data.offer.price === 0
      ) {
        setShowConfetti(true);
        const eventData = {
          event: ECheckoutPageEvent.BACK_TO_STORE,
          param: { isOrderSucceed: true },
        };
        window.postMessage(eventData, '*');
      } else {
        setShowSuccessOrder(true);
      }
    } else {
      setTimeout(() => {
        setErrorMessage(API.getOrder.data.publisherErrorMessage || '');
        setErrorCode(orderResolvedState);
        setShowFailed(true);
      }, 1000);
      setSelectedOffer(null);
    }
  }, [orderResolvedState, orderId]);

  const sendResolvedEvents = () => {
    if (typeof selectedOffer !== 'string') {
      const currSelectedOffer = selectedOffer as OfferData;
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_RESOLVED,
        {
          offer_id: currSelectedOffer?.offerId,
          result:
            orderResolvedState === EOrderStatus.CHARGE_SUCCEED
              ? EResultOptions.SUCCESS
              : EResultOptions.FAILED,
          offer_name: currSelectedOffer?.offerName,
          platform: getPlatformData(),
          type: currSelectedOffer?.offerType,
          sub_type: currSelectedOffer?.subType,
          reason:
            orderResolvedState !== EOrderStatus.CHARGE_SUCCEED ? API.getOrder.data.reason : '',
          index: currSelectedOffer?.indexToCollect,
        },
        EStorePhase.POST_LOGIN
      );
    }

    const { offerId, offerName, priceInUsd, salePercentage } = API.getOrder.data.offer;

    customEvents.sendCustomEvent(
      EEventsType.ORDER_RESOLVED,
      {
        status: orderResolvedState,
        order_id: orderId,
        offer_id: offerId,
        offer_name: offerName,
        offer_price_usd: priceInUsd,
        sale_percentage: salePercentage,
        platform: getPlatformData(),
      },
      EStorePhase.POST_LOGIN
    );
  };

  useEffect(() => {
    const eventHandler = (massageEvent: MessageEvent) => {
      if (
        massageEvent.origin !== window.location.origin &&
        !(massageEvent.origin as string).toLowerCase().includes('checkout-v2')
      )
        return;
      const { params, event } = massageEvent.data;
      switch (event) {
        case ECheckoutPageEvent.CHECKOUT_OPENED:
          if (new URLSearchParams(window.location.search).get('from') === 'campaign') {
            // TODO: remove this after implementing checkout deeplink event in IC
            customEvents.sendCustomEvent(EEventsType.CHECKOUT_OPENED, {
              offerId: selectedOffer,
              source: 'deeplink',
              source_type: 'dynamo',
            });
          }
          if (localStorageUtil.get(ELocalStorageKeys.IS_FREE_ORDER_SELECTED)) {
            API.getOffersV2.refetch();
            offersFetchInterval.current = setInterval(() => {
              API.getOffersV2.refetch();
            }, 1000);
          }
          localStorageUtil.remove(ELocalStorageKeys.IS_FREE_ORDER_SELECTED);
          break;
        case ECheckoutPageEvent.BACK_TO_STORE:
          customEvents.sendCustomEvent(
            EEventsType.COMPLETE_SCREEN_BACK_TO_SHOP,
            {},
            EStorePhase.POST_LOGIN
          );

          offersFetchInterval.current && clearInterval(offersFetchInterval.current);
          document.body.classList.remove('screen-lock');
          close();

          localStorageUtil.remove(ELocalStorageKeys.CURRENT_AVAILABILITY);
          break;
        case ECheckoutPageEvent.BACK_TO_GAME:
          customEvents.sendCustomEvent(
            EEventsType.COMPLETE_SCREEN_BACK_TO_GAME,
            {},
            EStorePhase.POST_LOGIN
          );
          window.location.assign(params.returnToGameLinkAddress);
          close();
          offersFetchInterval.current && clearInterval(offersFetchInterval.current);
          document.body.classList.remove('screen-lock');
          break;
        case ECheckoutPageEvent.SUPPORT:
          navigate(params.supportUrl);
          break;
        case ECheckoutPageEvent.ORDER_COMPLETED_SUCCESS:
          offersFetchInterval.current &&
            setTimeout(() => {
              clearInterval(offersFetchInterval.current), MAX_TIMEOUT_OFFERS_REFETCH;
            });
          break;
        case ECheckoutPageEvent.ORDER_COMPLETED_FAILED:
          offersFetchInterval.current &&
            setTimeout(() => {
              clearInterval(offersFetchInterval.current), MAX_TIMEOUT_OFFERS_REFETCH;
            });
          break;
      }
    };

    window.addEventListener('message', eventHandler);

    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  useEffect(() => {
    setDisplayLoader(!showSuccessOrder && !showFailed);

    if (showSuccessOrder || showFailed) {
      clearPoolingInterval();
    }
  }, [isSettled, showFailed, showSuccessOrder]);

  const validateOfferData = (checkoutProducts: CheckoutProduct[]) => {
    if ((selectedOffer as OfferData)?.productsSequence) {
      checkoutProducts.forEach((checkoutProduct: CheckoutProduct) => {
        const storeProduct = (selectedOffer as OfferData).productsSequence[
          (selectedOffer as OfferData).indexToCollect || 0
        ].products.find((storeProduct) => storeProduct.publisherProductId === checkoutProduct.sku);
        if (!storeProduct || storeProduct?.quantity != checkoutProduct?.amount) {
          console.error('mismatch between checkout and store products quantities');
        }
      });
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <>
      {session.url && session.token && (
        <div
          className={isSettled ? 'hide-iframe' : ''}
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            height: '100lvh',
            width: '100vw',
            zIndex: '1500',
          }}
        >
          <AppchargeCheckout
            checkoutToken={publisherMetaData.integration.checkoutPublicKey}
            checkoutUrl={session.url}
            sessionToken={session.token}
            playerId={localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA).playerId}
            referrerUrl={''}
            onClose={() => {
              close();
              document.body.classList.remove('screen-lock');
            }}
            onOrderCreated={(event: Partial<EventParams>) => {
              validateOfferData(event.products as CheckoutProduct[]);
            }}
            onInitialLoad={() => {
              setProcessing(false);
              setLoadingFromCampaign(false);
              document.body.classList.add('screen-lock');
            }}
            onOrderCompletedSuccessfully={onResolve}
            onOrderCompletedFailed={onResolve}
            onPaymentIntentSuccess={() => setIsSettled(true)}
            locale={locale as AppchargeLocale}
          />
        </div>
      )}
      {isSettled && (
        <Overlay overlayPercentage={90} visible={!displayLoader}>
          {showSuccessOrder && <SuccessOrder currencyCode={currencyCode} orderId={orderId} />}
          {showFailed && (
            <Failed
              errorCodeProp={errorCode || EOrderStatus.CHARGE_FAILED}
              orderIdProp={orderId}
              isIframeProp={true}
              currencyCodeProp={currencyCode}
              messageProp={errorMessage}
            />
          )}
        </Overlay>
      )}
      {showConfetti && (
        <Lottie
          animationData={animationData}
          loop={false}
          style={{
            position: 'absolute',
            zIndex: 100000,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
          onComplete={() => {
            setShowConfetti(false);
          }}
        />
      )}
      <Modal open={failedHashValidation}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            outline: 'none',
            color: '#FFF',
            fontFamily: publisherMetaData.storeTheme.general.font,
            width: '100%',
            padding: '0px 16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box marginBottom="15px" textAlign="center">
            <Typography
              variant="h3"
              component="h2"
              marginBottom={1.5}
              fontWeight={700}
              fontFamily="inherit"
              sx={{
                color: 'inherit',
              }}
            >
              {'Offer expired :('}
            </Typography>
            <Typography
              component="p"
              fontWeight={400}
              fontSize="14px"
              fontFamily="inherit"
              sx={{
                color: 'inherit',
              }}
            >
              {
                "The offer you're trying to collect has run out.. Please refresh the page for a fresh offer!"
              }
            </Typography>
          </Box>
          <Button
            variant="outlined"
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              width: '240px',
              height: '48px',
              padding: '14px 74px',
              borderRadius: '7px',
              borderColor: '#FFF',
              color: 'inherit',
              textTransform: 'none',
              fontFamily: 'inherit',
              '&:hover': {
                borderColor: 'inherit',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'inherit',
              },
            }}
            onClick={refreshPage}
          >
            {'Refresh'}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Checkout;
