import React from 'react';
import { DailyBonusBadge } from '@appcharge/shared-ui';
import { Stack } from '@mui/material';
import { dailyBonusBadgeProps } from '../../../components/HeaderWrapper/HeaderWrapper.types';
import { useTranslation } from 'react-i18next';
import { EBundlesInternalViewModel } from '../../../constants/enums';

enum DailyBonusPosition {
  LEFT = 'start',
  CENTER = 'center',
  RIGHT = 'end',
}

interface IDailyBonusWrapperProps {
  font: string;
  bundlesInternalViewModel: EBundlesInternalViewModel;
  dailyBonusBadges?: dailyBonusBadgeProps[];
  position?: DailyBonusPosition;
  timer?: string;
  onClickBadge?: (offerId?: string) => void;
}

const maxWidthConfigs: Record<any, any> = {
  [EBundlesInternalViewModel.BIG_FISH_CASINO]: '1024px',
  [EBundlesInternalViewModel.JACKPOT_SLOTS]: '1024px',
};

const getMaxWidth = (viewModel: EBundlesInternalViewModel) =>
  maxWidthConfigs[viewModel] || '1248px';

export const DailyBonusWrapper: React.FC<IDailyBonusWrapperProps> = ({
  font,
  dailyBonusBadges = [],
  position = DailyBonusPosition.LEFT,
  timer = '',
  onClickBadge,
  bundlesInternalViewModel,
}) => {
  if (dailyBonusBadges.length === 0) return null;

  const { t } = useTranslation();

  return (
    <Stack
      display="flex"
      alignItems={position}
      width="100%"
      height="auto"
      maxWidth={getMaxWidth(bundlesInternalViewModel)}
      padding="16px 20px 16px"
    >
      {dailyBonusBadges.map((badge) => (
        <DailyBonusBadge
          key={badge?.offerId}
          badgeCoverImage={badge?.badgeCoverImage}
          borderColor={badge?.borderColor || { colorOne: '#ffffff' }}
          offerId={badge.offerId}
          timer={timer}
          title={t('shop.dailyBonusBadgeTitle')}
          borderWidth="2px"
          titleSize="12px"
          font={font}
          onClickBadge={onClickBadge}
        />
      ))}
    </Stack>
  );
};
