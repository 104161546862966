import useCustomEvents from './useCustomEvents';
import { EEventsType, EStorePhase } from '../constants/enums';

export const useEvent = () => {
  const customEvents = useCustomEvents();

  const openTooltipEventHandler = () => {
    return customEvents.sendCustomEvent(
      EEventsType.TOOLTIP_OPEN,
      { phase: EStorePhase.POST_LOGIN },
      EStorePhase.POST_LOGIN
    );
  };

  return {
    openTooltipEventHandler,
  };
};
