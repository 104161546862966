import { BootResponse, OfferData, SectionData } from '../../../constants/apiResponses.types';
import useApi from '../../../hooks/useApi';
import {
  BagelCodeSection,
  BundlesLayout,
  DefaultSection,
  PipaSection,
  WhispersSection,
  LLCSection,
  MightyFuSection,
} from '@appcharge/shared-ui';
import { EBundlesInternalViewModel, ESectionsViewModel } from '../../../constants/enums';
import { Bundle } from '../../../components/Bundle/Bundle';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../style.scss';

const sectionDesignDic = {
  [ESectionsViewModel.DEFAULT]: DefaultSection,
  [ESectionsViewModel.BAGEL_CODE]: BagelCodeSection,
  [ESectionsViewModel.PIPA]: PipaSection,
  [ESectionsViewModel.Whispers]: WhispersSection,
  [ESectionsViewModel.LLC]: LLCSection,
  [ESectionsViewModel.MIGHTY_FU_CASINO]: MightyFuSection,
};

enum EConfigs {
  desktopMaxWidth = 'desktopMaxWidth',
  maxWidthOverride = 'maxWidthOverride',
  gapX = 'gapX',
  gapY = 'gapY',
  marginTop = 'marginTop',
}

const mapBundlesConfigs: Record<EConfigs, Partial<Record<EBundlesInternalViewModel, any>>> = {
  [EConfigs.desktopMaxWidth]: {
    [EBundlesInternalViewModel.JACKPOT_SLOTS]: '400px',
    [EBundlesInternalViewModel.BIG_FISH_CASINO]: '400px',
    [EBundlesInternalViewModel.LIGHTNING_LINK_CASINO]: '400px',
    [EBundlesInternalViewModel.MIGHTY_FU_CASINO]: '400px',
    [EBundlesInternalViewModel.HEART_OF_VEGAS]: '400px',
    [EBundlesInternalViewModel.PIPA]: '528px',
    [EBundlesInternalViewModel.PLAY_SIMPLE]: '400px',
  },
  [EConfigs.maxWidthOverride]: {
    [EBundlesInternalViewModel.MAIN_SUB]: '480px',
    [EBundlesInternalViewModel.WHISPERS]: '196px',
    [EBundlesInternalViewModel.BABA]: '400px',
  },
  [EConfigs.gapX]: {
    [EBundlesInternalViewModel.PLAY_SIMPLE]: '18px',
    [EBundlesInternalViewModel.WHISPERS]: '17px',
    [EBundlesInternalViewModel.PIPA]: '8px',
  },
  [EConfigs.gapY]: {
    [EBundlesInternalViewModel.BAGELCODE]: '8px',
    [EBundlesInternalViewModel.BAGELCODE2]: '8px',
    [EBundlesInternalViewModel.PIPA]: '8px',
  },
  [EConfigs.marginTop]: {
    [EBundlesInternalViewModel.PLAY_SIMPLE]: '0px',
    [EBundlesInternalViewModel.WHISPERS]: '25px',
  },
};

const defaultConfigs: Record<EConfigs, any> = {
  [EConfigs.desktopMaxWidth]: '1250px',
  [EConfigs.maxWidthOverride]: '400px',
  [EConfigs.gapX]: '12px',
  [EConfigs.gapY]: undefined,
  [EConfigs.marginTop]: '10px',
};

const getProperty: any = (property: EConfigs, viewModel: EBundlesInternalViewModel) =>
  mapBundlesConfigs[property][viewModel] || defaultConfigs[property];

interface IBundlesListProps {
  selectOffer: (offer: OfferData) => void;
}

export const BundlesWrapper: React.FC<IBundlesListProps> = ({ selectOffer }) => {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const { bundlesViewModel, sectionViewModel, bundlesInternalViewModel } =
    publisherMetaData.storeTheme.general;
  const sections = API.getOffersV2?.data?.sections as SectionData[];

  const SectionDesign = sectionDesignDic[sectionViewModel || ESectionsViewModel.DEFAULT];
  const [bundlesBySection, setBundlesBySection] = useState({} as Record<string, OfferData[]>);

  const [bundlesWithoutSections, setBundlesWithoutSections] = useState([] as OfferData[]);

  useEffect(() => {
    if (!API.getOffersV2?.data) return;

    const newBundleBySection: Record<string, OfferData[]> = {};
    const newBundlesWithoutSections: OfferData[] = [];

    API.getOffersV2?.data?.offers?.bundles?.forEach((bundle: OfferData) => {
      const sectionId = bundle.offerSectionId || 'no-section';
      const targetArray =
        sectionId === 'no-section'
          ? newBundlesWithoutSections
          : (newBundleBySection[sectionId] ||= []);
      targetArray.push(bundle);
    });

    // Order bundles according to sections
    const orderedBundles = Object.fromEntries(
      sections
        .filter((section) => newBundleBySection[section.sectionId]?.length)
        .map((section) => [section.sectionId, newBundleBySection[section.sectionId]])
    );
    setBundlesBySection(orderedBundles);
    setBundlesWithoutSections(newBundlesWithoutSections);
  }, [API.getOffersV2?.data]);

  const mapBundlesToLayout = (bundles: OfferData[]) => {
    if (bundles.length === 0) return null;
    return (
      <BundlesLayout
        bundlesViewModel={bundlesViewModel}
        bundlesInternalViewModel={publisherMetaData.storeTheme.general.bundlesInternalViewModel}
        gapX={getProperty(EConfigs.gapX, bundlesInternalViewModel)}
        gapY={getProperty(EConfigs.gapY, bundlesInternalViewModel)}
        maxWidthOverride={getProperty(EConfigs.maxWidthOverride, bundlesInternalViewModel)}
        desktopMaxWidth={getProperty(EConfigs.desktopMaxWidth, bundlesInternalViewModel)}
        marginTop={getProperty(EConfigs.marginTop, bundlesInternalViewModel)}
      >
        {bundles.map((bundle: OfferData, index: number) => (
          <div key={index} className={'bundle'}>
            <Bundle
              index={index}
              key={bundle.offerId}
              data={bundle}
              selectOffer={() => selectOffer(bundle)}
            />
          </div>
        ))}
      </BundlesLayout>
    );
  };

  return (
    <Box className={`bundles-wrapper ${bundlesInternalViewModel}`}>
      {bundlesBySection &&
        Object.entries(bundlesBySection).map(([sectionId, sectionBundles], index) => {
          const currentSection = sections?.find((item: any) => item?.sectionId === sectionId);
          return (
            <Box
              sx={{
                width: '100%',
                display: 'inline-flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              key={index}
            >
              {currentSection && (
                <SectionDesign
                  sectionName={currentSection.name}
                  image={currentSection.image || ''}
                />
              )}
              {mapBundlesToLayout(sectionBundles)}
            </Box>
          );
        })}

      <Box
        sx={{
          width: '100%',
          display: 'inline-flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/*Make sure bundles without sections will be at the bottom of the page*/}
        {mapBundlesToLayout(bundlesWithoutSections)}
      </Box>
    </Box>
  );
};
